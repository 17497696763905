// noinspection SpellCheckingInspection
export default {
  "401.description": "Zugang verwehrt: Sie müssen angemeldet sein",
  "403.description": "Zugang verwehrt: Unzureichende Rechte",
  "404.description": "Seite nicht gefunden",
  "actions.adminLink": "Verwaltung",
  "actions.findFreelance": "Pflegefachperson suchen",
  "actions.join": "Mitglied werden",
  "actions.login": "myCuracasa",
  "actions.newcuracasa": "Pflegefachperson suchen:",
  "actions.newcuracasalink": "hier verfügbar",
  "actions.logout": "Abmelden",
  "actions.myProfile": "myCuracasa",
  "addContact.comment": "Kommentar",
  "addContact.duration": "Dauer (Minuten)",
  "addContact.type1": "Typ 1",
  "addContact.type2": "Typ 2",
  "adminContacts.seePDF": "PDF der Kontakte",
  "adminInterventionGroup.confirmDeleteGroup":
    "Sind Sie sicher, dass Sie diese Interventionsgruppe löschen möchten ?",
  "adminInterventionGroups.addButton": "Interventionsgruppe hinzufügen",
  "adminMenu.name": "Name",
  "adminMenu.position": "Position",
  "adminMenu.singlePage": "Ein-Seiten-Menü",
  "adminNews.position": "Position",
  "adminNews.title": "Titel",
  "adminNews.text": "Text",
  "adminNews.link": "Link",
  "adminPage.child": "Unterseite",
  "adminPage.content": "Inhalt",
  "adminPage.menu": "Menü",
  "adminPage.position": "Position",
  "adminPage.title": "Titel",
  "adminPages.addMenu": "Zum Bildschirmmenü hinzufügen",
  "adminPages.addPage": "Seite einzufügen",
  "adminPages.confirmDeleteMenu":
    "Sind Sie sicher, dass Sie dieses Menü löschen möchten ?",
  "adminPages.confirmDeletePage":
    "Sind Sie sicher, dass Sie diese Seite löschen möchten ?",
  "adminPages.noPage": "Keine Seite in diesem Bildschirmmenü",
  "adminProfile.tabs.contacts": "Kontakte",
  "adminProfile.tabs.data": "Daten",
  "adminProfiles.addButton": "Erstelle ein Profil",
  "adminProfiles.freelanceSince": "Freiberuflich seit",
  "adminProfiles.inputLabel": "Suchen",
  "adminProfiles.search": "Suche ein Profil",
  "adminProfiles.table.nextPage": "Nächste Seite",
  "adminProfiles.table.of": "von",
  "adminProfiles.table.previousPage": "Vorherige Seite",
  "adminProfiles.table.rowsPerPage": "Zeilen pro Seite",
  "adminProfiles.table.sort": "Sortieren",
  "adminProfiles.table.viewColumns.toolbar": "Siehe die Spalten",
  "adminProfiles.table.viewColumns.title": "Spalten anzeigen",
  "adminProfiles.table.viewColumns.titleAria": "Spalten ein-/ausblenden",
  "adminSelfEvals.asiNumbers": "SBK-Nummer",
  "adminSelfEvals.title": "Verwaltung des Zugangs zur Selbstbeurteilung",
  "contactDetails.duration": "{number} Minuten",
  "contactsPDF.history": "Kontaktverlauf",
  "createMenu.title": "Erstellen ein Bildschirmenü",
  "createPage.title": "Erstellen ein Seite",
  "dashboard.contactsLink": "Kontakte exportieren",
  "dashboard.exportLink": "Daten exportieren",
  "dashboard.interventionGroupsLinks": "Interventionsgruppe anzeigen",
  "dashboard.newsLink": "News bearbeiten",
  "dashboard.pagesLink": "Bildschirmmenü und Seiten anzeigen",
  "dashboard.profilesLink": "Profile anzeigen",
  "dashboard.selfEvalsLink": "Zugang zur Selbstevaluation",
  "dashboard.title": "Bedienungsfeld",
  "editMenu.title": "Bearbeiten ein Bildschirmenü",
  "editPage.title": "Bearbeiten ein Seite",
  "error.address.streetOrPoBoxRequired": "Strasse oder Postfach erforderlich",
  "error.integer": "Muss eine ganze Zahl sein",
  "error.invalidAsiNumber": "Ungültige SBK-Nummer : {number}",
  "error.required": "Erforderlich",
  "error.tooHigh": "Zu hoch",
  "error.tooLong": "Zu lang",
  "error.tooLow": "Zu niedrig",
  "error.tooShort": "Zu kurz",
  "error.unspecified":
    "Ein unbekannter Fehler ist aufgetreten. Versuchen Sie, das zu aktualisieren",
  "export.appLanguages.any": "Alle Sprachen",
  "export.curacasaMember.any": "Mitglied oder nicht",
  "export.curacasaMember.no": "Kein Curacasa Mitglied",
  "export.curacasaMember.yes": "Curacasa Mitglied",
  "export.domain": "Spezialität",
  "export.domains.any": "Alle Spezialitäten",
  "export.interventionCanton": "Interventionskanton",
  "export.interventionCanton.any": "Alle Interventionskantone",
  "export.title": "Exportiere aktive Krankenschwesterdaten",
  "exportContacts.title": "Exportiere Kontaktverlauf",
  "find.advanced": "Erweiterte Suche",
  "find.cantonAdmission.any": "Alle Kantone",
  "find.cantonAdmission": "Kantonale Berufsausübungsbewilligung",
  "find.domain": "Spezialität",
  "find.domains.any": "Alle Spezialitäten",
  "find.interventionDelay.any": "Alle Zeiträume",
  "find.interventionDelay.available": "Sofortige Verfügbarkeit",
  "find.interventionDelay.max2Months": "Max. 2 Monate warten",
  "find.interventionDelay.max3Weeks": "Max. 3 Wochen",
  "find.interventionDelay.unavailable": "Keine Verfügbarkeit",
  "find.label.any": "Mit oder ohne Label",
  "find.label.with": "Mit Label",
  "find.label.without": "Ohne Label",
  "find.practiceArea": "Region",
  "find.practiceArea.any": "Alle Regionen",
  "find.practiceLanguage": "Sprache",
  "find.practiceLanguages.any": "Alle Sprachen",
  "find.simple": "Einfache Suche",
  "find.title": "Pflegefachperson suchen",
  "footer.address.poBox": "Postfach 1010",
  "footer.address.town": "3006 Bern",
  "footer.curacasa.openingHours.1": "Mo. bis Fr. : 09.00-12.00",
  "footer.curacasa.openingHours.2": "Mo. und Mi. : 13.30-17.00",
  "footer.link.elog": "https://www.e-log.ch/de/",
  "footer.link.hin": "https://www.hin.ch/produkte/hin-curacasa/",
  "footer.support.openingHours": "Mo. bis Mi. : 17.00-18.00",
  "footer.support.title": "Support myCuracasa und e-log",
  "general.action.back": "Rückkehr",
  "general.action.cancel": "Abbrechen",
  "general.action.choose": "Wählen",
  "general.action.clear": "Löschen",
  "general.action.confirm": "Bestätigen",
  "general.action.download": "Herunterladen",
  "general.action.save": "Speichern",
  "general.action.search": "Suchen",
  "general.action.send": "Senden",
  "general.action.transmit": "Senden",
  "general.address": "Adresse",
  "general.birthDate": "Geburtsdatum",
  "general.date": "Datum",
  "general.date.invalid": "Ungültiges Datum",
  "general.date.today": "Heute",
  "general.email": "Email",
  "general.enable": "Aktivieren",
  "general.firstName": "Vornamen",
  "general.language": "Sprache",
  "general.lastName": "Namen",
  "general.month": "Monat",
  "general.name": "Name",
  "general.no": "Nein",
  "general.noResult": "Keine Ergebnisse",
  "general.nResults": "{number} Ergebnisse",
  "general.phone": "Telefonnummer",
  "general.saved": "Gespeichert",
  "general.yes": "Ja",
  "interventionGroup.comment": "Kommentar",
  "interventionGroup.members.add": "Mitglied hinzufügen",
  "interventionGroup.members.confirmRemove":
    "Sind Sie sicher, dass Sie dieses Mitglied löschen möchten ?",
  "interventionGroup.members.instructions":
    "Geben Sie eine SBK-Nummer ein (z.B. 12345.1)",
  "interventionGroup.members.noMember": "Kein Mitglied",
  "interventionGroup.members.notFound":
    "Diese SBK-Nummer is ungültig oder die Krankenschwester hat bereits eine Interventionsgruppe",
  "interventionGroup.members.promptConfirm":
    "Diese SBK-Nummer gehört zu {name}. Ist es richtig ?",
  "interventionGroup.members.title": "Mitglieder",
  "interventionGroup.numMembers": "{number} Mitglieder",
  "join.comment": "Kommentar",
  "join.fee.amount": "Mitgliedsbeitrag",
  "join.fee.info":
    "Der Mitgliedsbeitrag ist je nach Monat der Mitgliedschaft degressiv",
  "join.fee.title": "Mitgliedsbeitrag",
  "join.success.text":
    "Ihre Anfrage wurde gesendet, Sie erhalten eine Bestätigung per E-Mail. Das Sekretariat wird sich mit Ihnen in Verbindung setzen, um Ihre Mitgliedschaft abzuschließen.",
  "join.success.title": "Erfolgreich gesendet",
  "join.title": "Anmeldung Mitglied",
  "linkProfile.codeLabel": "Code",
  "linkProfile.contact":
    "Bei Fragen kontaktieren Sie bitte folgende Adresse: {address}",
  "linkProfile.failed": "Inkorrekter Code. Bitte erneut versuchen.",
  "linkProfile.notFound":
    "Kein Profil gefunden: Code ungültig oder bereits verwendet.",
  "linkProfile.promptCode":
    "Sie haben noch kein Profil. Geben Sie den erhaltenen Code hier ein:",
  "linkProfile.promptConfirm": "Sind Sie {name}, Inhaber dieses Profils ?",
  "linkProfile.success": "Profil erfolgreich verknüpft. Ladet.",
  "linkProfile.title": "Profil verknüpfen.",
  "nurse.action.preview": "Profilvorschau",
  "nurse.asi": "SBK-Nummer",
  "nurse.asiMember": "SBK-Mitglied",
  "nurse.cantonAdmissions": "Kantonale Berufsausübungsbewilligung",
  "nurse.certification": "Curacasa Qualitätslabel",
  "nurse.certification.2020": "Qualitätslabel 2022-2023",
  "nurse.comment": "Öffentlicher Kommentar",
  "nurse.company": "Gruppe / Team",
  "nurse.confirmLeaveInterventionGroup":
    "Sind Sie sicher, dass Sie Ihre Interventionsgruppe verlassen möchten ?",
  "nurse.curacasaMember": "Curacasa Mitglieder",
  "nurse.districts": "Region der Intervention",
  "nurse.domains": "Spezialisierungen",
  "nurse.employeePercentage": "Anstellungsgrad in Prozent",
  "nurse.freelancePercentage": "Freiberuflichkeitsgrad in Prozent",
  "nurse.interventionDelay": "Interventionszeitraum",
  "nurse.interventionDelay.unspecified": "Nicht spezifiziert",
  "nurse.interventionGroup": "Interventionsgruppe",
  "nurse.interventionGroup.none": "Keine Interventionsgruppe",
  "nurse.interventionGroup.withName": "Interventionsgruppe : {name}",
  "nurse.interventionGroup.withoutName": "Interventionsgruppe : ",
  "nurse.join": "Mitglied werden",
  "nurse.leaveInterventionGroup": "Interventionsgruppe verlassen",
  "nurse.notCertified": "ohne Beschriftung",
  "nurse.period.add": "Zeitraum fügen",
  "nurse.period.end": "Ende",
  "nurse.period.start": "Start",
  "nurse.picture": "Bild",
  "nurse.practiceLanguages": "Arbeitssprachen",
  "nurse.president": "Präsident-in",
  "nurse.rcc": "ZSR-Nummer",
  "nurse.section": "SBK Sektion",
  "nurse.selfEvalEnabled": "Zugängliche Selbstevaluation",
  "nurse.specialMember": "Komiteemitglied oder Angestellter",
  "nurse.visible": "Öffentlich zugängliches Profil",
  "nurse.visible.tooltip":
    "Mindestens 1 kantonale Berufsausübungsbewilligung erforderlich",
  "nurse.website": "Webseite",
  "nurse.psychiatryExperience": "APsy",
  "nurse.psychiatryExperienceArt":
    "Zweijährige praktische Tätigkeit in der Fachrichtung Psychiatrie gemäss Art. 7 Abs. 2bis lit. b KLV",
  "nurse.psychiatryAsterix": "APsy *: ",
  "nurse.psychiatryAsterixContent":
    "APsy *: Zweijährige praktische Tätigkeit in der Fachrichtung Psychiatrie gemäss Art. 7 Abs. 2bis lit. b KLV",
  "nurseDetails.notFound": "Pflegefachperson nicht gefunden",
  "profile.address.add": "Adresse hinzufügen",
  "profile.address.canton": "Kanton",
  "profile.address.country": "Land",
  "profile.address.isContact": "Kontaktadresse",
  "profile.address.poBox": "Postfach",
  "profile.address.public": "Öffentlich ersichtliche Adresse",
  "profile.address.setContact": "Als Kontaktadresse festlegen",
  "profile.address.street": "Strasse und Hausnummer",
  "profile.address.town": "Ort",
  "profile.address.type": "Typ",
  "profile.address.zip": "PLZ",
  "profile.addresses": "Adressen",
  "profile.email.add": "E-Mailadresse hinzufügen",
  "profile.email.address": "Adresse",
  "profile.email.isContact": "Kontakt per Mail:",
  "profile.email.public": "Öffentlich ersichtliche E-Mailadresse",
  "profile.email.setContact": "Als Kontaktmailadresse festlegen",
  "profile.email.type": "Typ",
  "profile.emails": "E-Mail",
  "profile.notNurse": "Ist keine Krankenschwester",
  "profile.phone.add": "Telefonnummer hinzufügen",
  "profile.phone.isContact": "Kontakt per Telefon:",
  "profile.phone.number": "Telefonnummer",
  "profile.phone.public": "Öffentlich ersichtliche Telefonnummer",
  "profile.phone.setContact": "Als Kontakttelefon festlegen",
  "profile.phone.type": "Typ",
  "profile.phones": "Telefonnummern",
  "profile.salutation": "Titel",
  "profile.tabs.personalInfos": "Persönliche Angaben",
  "profile.tabs.preview": "Vorschau",
  "profile.tabs.professionalInfos": "Berufliche Angaben",
  "profile.tabs.selfEval": "Selbstevaluation",
  "profile.type": "Profiltyp",
  "profileInfos.latestContacts": "Neueste Kontakte",
  "self-eval.2019": "Selbstevaluation 2019",
  "self-eval.2020": "Selbstevaluation 2020",
  "self-eval.confirmation.text":
    "Wir bestätigen hiermit, dass die Selbstevaluation des Curacasa-Qualitätsprogramms ausgefüllt wurde.",
  "self-eval.confirmation.title": "Bestätigung",
  "self-eval.criterion.name": "Kriterium {identifier}",
  "self-eval.disabled.member":
    "Die Zahlung des Beitrags wurde noch nicht erfasst",
  "self-eval.disabled.nonMember":
    "Die erste Zahlung für das Qualitätsprogramm wurde noch nicht registriert",
  "self-eval.description": "Beschreibung",
  "self-eval.glossary.title": "Glossar",
  "self-eval.grade": "Skala",
  "self-eval.grade.empty": "...",
  "self-eval.infos.alreadyTransmitted":
    "Sie haben Ihre Selbstevaluation bereits gesendet",
  "self-eval.infos.end2019.transmitted":
    "Das Formular für die Selbstevaluation 2020 wird ab dem 1.07.2020 verfügbar sein. Ihre Angaben sind bis zu diesem Datum gespeichert.",
  "self-eval.infos.notSubmitted":
    "Sobald Sie fertig sind, können Sie Ihre Selbstevaluation über die Schaltfläche Senden senden",
  "self-eval.infos.submitWarning":
    "Achtung, einmal gesendet, kann nicht geändert werden!",
  "self-eval.infos.successfullyTransmitted": "Erfolgreich gesendet",
  "self-eval.infos.timeLimit": "Frist : 20. Februar 2022",
  "self-eval.instructions.title": "Anleitung",
  "self-eval.notTransmitted": "Selbstevaluation nicht gesendet",
  "self-eval.scale.title": "Skala zur Beurteilung",
  "self-eval.seePDF": "PDF der Selbstevaluation",
  "self-eval.seePDF.2019": "PDF der Selbstevaluation 2019",
};
