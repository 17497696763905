import auth0 from 'auth0-js';
import {env} from '../env';

class Auth {
  /**
   * Has the session been renewed yet ?
   */
  renewed;

  /**
   * Use this in all requests to the API (Authentication Bearer).
   */
  accessToken;

  tokenChangeCb;

  sessionExpiration;
  tokenRenewalTimeout;

  auth0 = new auth0.WebAuth({
    domain: env.auth0.domain,
    clientID: env.auth0.clientId,
    redirectUri: env.auth0.callbackUrl,
    audience: env.auth0.audience,
    responseType: 'token id_token',
    scope: 'openid',
  });

  constructor() {
    this.renewed = false;

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.processAuthResult = this.processAuthResult.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.renewSession = this.renewSession.bind(this);
  }

  renewSession() {
    return new Promise((resolve, reject) => {
      const res = () => {
        this.renewed = true;
        resolve();
      };
      if (localStorage.getItem('renewSession') === 'true')
        this.auth0.checkSession({}, (err, authResult) => {
          //ERREUR ICI AVEC NOUVELLE VERSION SAFARI
          console.log("renewSession error: ", err);
          this.processAuthResult(err, authResult, res, reject);
        });
      else
        res();
    });
  }

  /**
   * Call this when the user clicks the 'login' button.
   */
  login() {
    this.auth0.authorize();
  }

  reset(client) {
    if (client)
      client.resetStore();
    clearTimeout(this.tokenRenewalTimeout);
    this.sessionExpiration = undefined;
    this.accessToken = undefined;
    if (this.tokenChangeCb)
      this.tokenChangeCb();
    localStorage.removeItem('renewSession');
  }

  /**
   * Call this when the user clicks the 'logout' button.
   */
  logout(client) {
    this.reset(client);
    this.auth0.logout({returnTo: window.location.origin});
  }

  /**
   * Call this when displaying the '/login' page if the following code returns true :
   * ```typescript
   * // locationHash is the part following the '#' in the url.
   * // It is typically accessed through window.location.hash
   * /access_token|id_token|error/.test(locationHash)
   * ```
   *
   * This method returns a Promise to get the login feedback.
   *
   * Code example (supposed in the page load event handler) :
   * ```typescript
   * // import auth from ...;
   * if (/access_token|id_token|error/.test(window.location.hash))
   *   auth.handleAuthentication()
   *     .then(() => {
   *         // authentication successful, redirect to /profile
   *
   *     })
   *     .catch(err => {
   *       // show error
   *     })
   * ```
   */
  handleAuthentication(hash) {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash({hash}, (err, authResult) => {
        this.processAuthResult(err, authResult, resolve, reject);
      });
    });
  }

  processAuthResult(err, authResult, resolve, reject) {
    if (err) {
      this.reset();
      if (reject)
        reject(err);
      else if (process.env.NODE_ENV !== 'production')
        console.error(err);
    } else if (authResult && authResult.accessToken) {
      localStorage.setItem('renewSession', 'true');
      this.sessionExpiration = (authResult.expiresIn * 1000) + Date.now();
      this.accessToken = authResult.accessToken;
      if (this.tokenChangeCb)
        this.tokenChangeCb();
      this.tokenRenewalTimeout = setTimeout(this.renewSession, this.sessionExpiration - Date.now());
      if (resolve)
        resolve();
    } else if (reject)
      reject('Could not get an access token. Check the configuration.');
    else if (process.env.NODE_ENV !== 'production')
      console.error('Could not get an access token. Check the configuration.');
  }
}

export const auth = new Auth();
