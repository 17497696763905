// noinspection SpellCheckingInspection
export default {
  "401.description": "Accès refusé : vous devez être connecté",
  "403.description": "Accès refusé : droits insuffisants",
  "404.description": "Page introuvable",
  "actions.adminLink": "Administration",
  "actions.findFreelance": "Chercher un ou une infirmière",
  "actions.join": "Devenir membre",
  "actions.login": "myCuracasa",
  "actions.newcuracasa": "Chercher un ou une infirmière :",
  "actions.newcuracasalink": "disponible ici",
  "actions.logout": "Déconnexion",
  "actions.myProfile": "myCuracasa",
  "addContact.comment": "Commentaire",
  "addContact.duration": "Durée (minutes)",
  "addContact.type1": "Type 1",
  "addContact.type2": "Type 2",
  "adminContacts.seePDF": "PDF des contacts",
  "adminInterventionGroup.confirmDeleteGroup":
    "Êtes-vous sûr de vouloir supprimer ce groupe d'intervention ?",
  "adminInterventionGroups.addButton": "Ajouter un groupe d'intervention",
  "adminMenu.name": "Nom",
  "adminMenu.position": "Position",
  "adminMenu.singlePage": "Menu à page unique",
  "adminNews.position": "Position",
  "adminNews.title": "Titre",
  "adminNews.text": "Texte",
  "adminNews.link": "Lien",
  "adminPage.child": "Sous-page",
  "adminPage.content": "Contenu",
  "adminPage.menu": "Menu",
  "adminPage.position": "Position",
  "adminPage.title": "Titre",
  "adminPages.addMenu": "Ajouter un menu",
  "adminPages.addPage": "Ajouter une page",
  "adminPages.confirmDeleteMenu":
    "Êtes-vous sûr de vouloir supprimer ce menu ?",
  "adminPages.confirmDeletePage":
    "Êtes-vous sûr de vouloir supprimer cette page ?",
  "adminPages.noPage": "Aucune page dans ce menu",
  "adminProfile.tabs.contacts": "Contacts",
  "adminProfile.tabs.data": "Données",
  "adminProfiles.addButton": "Créer un profil",
  "adminProfiles.freelanceSince": "Indépendant depuis",
  "adminProfiles.inputLabel": "Rechercher...",
  "adminProfiles.search": "Rechercher un profil",
  "adminProfiles.table.nextPage": "Page suivante",
  "adminProfiles.table.of": "sur",
  "adminProfiles.table.previousPage": "Page précédente",
  "adminProfiles.table.rowsPerPage": "Lignes par page",
  "adminProfiles.table.sort": "Trier",
  "adminProfiles.table.viewColumns.toolbar": "Voir les colonnes",
  "adminProfiles.table.viewColumns.title": "Afficher les colonnes",
  "adminProfiles.table.viewColumns.titleAria": "Afficher/cacher les colonnes",
  "adminSelfEvals.asiNumbers": "Numéros ASI",
  "adminSelfEvals.title": "Gérer l'accès à l'auto-évaluation",
  "contactDetails.duration": "{number} minutes",
  "contactsPDF.history": "Historique des contacts",
  "createMenu.title": "Créer un menu",
  "createPage.title": "Créer une page",
  "dashboard.contactsLink": "Exporter les contacts",
  "dashboard.exportLink": "Exporter des données",
  "dashboard.interventionGroupsLinks": "Gérer les groupes d'intervention",
  "dashboard.newsLink": "Gérer les news",
  "dashboard.pagesLink": "Gérer les menus et les pages",
  "dashboard.profilesLink": "Gérer les profils",
  "dashboard.selfEvalsLink": "Accès à l'auto-évaluation",
  "dashboard.title": "Tableau de bord",
  "editMenu.title": "Modifier un menu",
  "editPage.title": "Modifier une page",
  "error.address.streetOrPoBoxRequired": "Rue ou case postale requis",
  "error.integer": "Doit être un nombre entier",
  "error.invalidAsiNumber": "Numéro ASI invalide : {number}",
  "error.required": "Requis",
  "error.tooHigh": "Valeur trop élevée",
  "error.tooLong": "Trop long",
  "error.tooLow": "Valeur trop basse",
  "error.tooShort": "Trop court",
  "error.unspecified":
    "Une erreur inconnue est survenue. Essayez de rafraîchir la page",
  "export.appLanguages.any": "Toutes les langues",
  "export.curacasaMember.any": "Membre ou pas",
  "export.curacasaMember.no": "Pas membre de Curacasa",
  "export.curacasaMember.yes": "Membre de Curacasa",
  "export.domain": "Spécialité",
  "export.domains.any": "Toutes les spécialités",
  "export.interventionCanton": "Canton d'invervention",
  "export.interventionCanton.any": "Tous les cantons",
  "export.title": "Exporter les données des infirmiers actifs",
  "exportContacts.title": "Exporter l'historique des contacts",
  "find.advanced": "Recherche avancée",
  "find.cantonAdmission.any": "Tous les cantons",
  "find.cantonAdmission": "Autorisation cantonale",
  "find.domain": "Spécialité",
  "find.domains.any": "Toutes les spécialités",
  "find.interventionDelay.any": "Tous les délais",
  "find.interventionDelay.available": "Disponibilité immédiate",
  "find.interventionDelay.max2Months": "Max. 2 mois d'attente",
  "find.interventionDelay.max3Weeks": "Max. 3 semaines d'attente",
  "find.interventionDelay.unavailable": "Aucune disponibilité",
  "find.label.any": "Avec ou sans label",
  "find.label.with": "Avec label",
  "find.label.without": "Sans label",
  "find.practiceArea": "Région",
  "find.practiceArea.any": "Toutes les régions",
  "find.practiceLanguage": "Langue",
  "find.practiceLanguages.any": "Toutes les langues",
  "find.simple": "Recherche simple",
  "find.title": "Rechercher un ou une infirmière",
  "footer.address.poBox": "Case postale 1010",
  "footer.address.town": "3006 Berne",
  "footer.curacasa.openingHours.1": "Lu. à Ve. : 09.00-12.00",
  "footer.curacasa.openingHours.2": "Lu. et Me. : 13.30-17.00",
  "footer.link.elog": "https://www.e-log.ch/fr/",
  "footer.link.hin": "https://www.hin.ch/fr/produkte/hin-curacasa/",
  "footer.support.openingHours": "Lu. à Me. : 17.00-18.00",
  "footer.support.title": "Soutien myCuracasa et e-log",
  "general.action.back": "Retour",
  "general.action.cancel": "Annuler",
  "general.action.choose": "Choisir",
  "general.action.clear": "Effacer",
  "general.action.confirm": "Confirmer",
  "general.action.download": "Télécharger",
  "general.action.save": "Enregistrer",
  "general.action.search": "Rechercher",
  "general.action.send": "Envoyer",
  "general.action.transmit": "Transmettre",
  "general.address": "Adresse",
  "general.birthDate": "Date de naissance",
  "general.date": "Date",
  "general.date.invalid": "Date invalide",
  "general.date.today": "Aujourd'hui",
  "general.email": "Email",
  "general.enable": "Activer",
  "general.firstName": "Prénom",
  "general.language": "Langue",
  "general.lastName": "Nom",
  "general.month": "Mois",
  "general.name": "Nom",
  "general.no": "Non",
  "general.noResult": "Aucun résultat",
  "general.nResults": "{number} résultats",
  "general.phone": "Téléphone",
  "general.saved": "Enregistré",
  "general.yes": "Oui",
  "interventionGroup.comment": "Commentaire",
  "interventionGroup.members.add": "Ajouter un membre",
  "interventionGroup.members.confirmRemove":
    "Êtes-vous sûr de vouloir supprimer ce membre ?",
  "interventionGroup.members.instructions":
    "Entrez un numéro ASI (par exemple 12345.1)",
  "interventionGroup.members.noMember": "Aucun membre",
  "interventionGroup.members.notFound":
    "Ce numéro ASI est invalide ou la personne a déjà un groupe d'intervention",
  "interventionGroup.members.promptConfirm":
    "Ce numéro ASI appartient à {name}. Est-ce correct ?",
  "interventionGroup.members.title": "Membres",
  "interventionGroup.numMembers": "{number} membres",
  "join.comment": "Commentaire",
  "join.fee.amount": "Cotisation",
  "join.fee.info":
    "La cotisation d'adhésion est dégressive selon le mois d'adhésion",
  "join.fee.title": "Cotisation",
  "join.success.text":
    "Votre demande a bien été envoyée, vous allez recevoir une confirmation par email. Le secrétariat vous contactera pour finaliser votre adhésion.",
  "join.success.title": "Envoi réussi",
  "join.title": "Devenir membre",
  "linkProfile.codeLabel": "Code",
  "linkProfile.contact":
    "Si vous avez des questions, contactez-nous à l'adresse {address}",
  "linkProfile.failed":
    "Erreur lors de l'utilisation du code. Veuillez réessayer",
  "linkProfile.notFound":
    "Aucun profil trouvé : le code est invalide ou déjà utilisé",
  "linkProfile.promptCode":
    "Vous n'avez pas encore de profil. Si vous avez reçu un code, insérez-le ici :",
  "linkProfile.promptConfirm":
    "Ce profil appartient à {name}. Est-ce bien vous ?",
  "linkProfile.success": "Profil lié avec succès. Il va maintenant être chargé",
  "linkProfile.title": "Lier un profil",
  "nurse.action.preview": "Prévisualiser le profil",
  "nurse.asi": "Numéro ASI",
  "nurse.asiMember": "Membre de l'ASI",
  "nurse.cantonAdmissions": "Autorisations de pratique cantonales",
  "nurse.certification": "Label qualité Curacasa",
  "nurse.certification.2020": "Label qualité 2022-2023",
  "nurse.comment": "Commentaire public",
  "nurse.company": "Groupe / Team",
  "nurse.confirmLeaveInterventionGroup":
    "Êtes-vous sûr de vouloir quitter votre groupe d'intervention ?",
  "nurse.curacasaMember": "Membre de Curacasa",
  "nurse.districts": "Région d'intervention",
  "nurse.domains": "Spécialités",
  "nurse.employeePercentage": "Taux employé(e) %",
  "nurse.freelancePercentage": "Taux indépendant(e) %",
  "nurse.interventionDelay": "Délai d'intervention",
  "nurse.interventionDelay.unspecified": "Non spécifié",
  "nurse.interventionGroup": "Groupe d'intervention",
  "nurse.interventionGroup.none": "Aucun groupe d'intervention",
  "nurse.interventionGroup.withName": "Groupe d'intervention : {name}",
  "nurse.interventionGroup.withoutName": "Groupe d'intervention : ",
  "nurse.join": "Devenir membre",
  "nurse.leaveInterventionGroup": "Quitter le groupe",
  "nurse.notCertified": "Sans label",
  "nurse.period.add": "Ajouter une période",
  "nurse.period.end": "Fin",
  "nurse.period.start": "Début",
  "nurse.picture": "Photo",
  "nurse.practiceLanguages": "Langues d'intervention",
  "nurse.president": "Président",
  "nurse.rcc": "Numéro RCC",
  "nurse.section": "Section ASI",
  "nurse.selfEvalEnabled": "Auto-évaluation accessible",
  "nurse.specialMember": "Membre du comité ou employé",
  "nurse.visible": "Profil visible au public",
  "nurse.visible.tooltip":
    "Requiert au moins 1 autorisation de pratique cantonale",
  "nurse.website": "Site web",
  "nurse.psychiatryExperience": "APsy",
  "nurse.psychiatryExperienceArt":
    "Expérience professionnelle de deux ans dans le domaine psychiatrique selon l’art. 7 al. 2bis let.b OPAS",
  "nurse.psychiatryAsterix": "APsy *: ",
  "nurse.psychiatryAsterixContent":
    "APsy * : Expérience professionnelle de deux ans dans le domaine psychiatrique selon l’art. 7 al. 2bis let.b OPAS",
  "nurseDetails.notFound": "Infirmier/ère introuvable",
  "profile.address.add": "Ajouter une adresse",
  "profile.address.canton": "Canton",
  "profile.address.country": "Pays",
  "profile.address.isContact": "Adresse de contact",
  "profile.address.poBox": "Case postale",
  "profile.address.public": "Adresse visible au public",
  "profile.address.setContact": "Définir en tant qu'adresse de contact",
  "profile.address.street": "Rue et numéro",
  "profile.address.town": "Lieu",
  "profile.address.type": "Type",
  "profile.address.zip": "NPA",
  "profile.addresses": "Adresses",
  "profile.email.add": "Ajouter une adresse email",
  "profile.email.address": "Adresse",
  "profile.email.isContact": "Email de contact",
  "profile.email.public": "Email visible au public",
  "profile.email.setContact": "Définir en tant qu'email de contact",
  "profile.email.type": "Type",
  "profile.emails": "Emails",
  "profile.notNurse": "N'est pas infirmier/ère",
  "profile.phone.add": "Ajouter un téléphone",
  "profile.phone.isContact": "Téléphone de contact",
  "profile.phone.number": "Numéro",
  "profile.phone.public": "Téléphone visible au public",
  "profile.phone.setContact": "Définir en tant que numéro de contact",
  "profile.phone.type": "Type",
  "profile.phones": "Téléphones",
  "profile.salutation": "Titre",
  "profile.tabs.personalInfos": "Informations personnelles",
  "profile.tabs.preview": "Prévisualisation",
  "profile.tabs.professionalInfos": "Informations professionnelles",
  "profile.tabs.selfEval": "Auto-évaluation",
  "profile.type": "Type de profil",
  "profileInfos.latestContacts": "Derniers contacts",
  "self-eval.2019": "Auto-évaluation 2019",
  "self-eval.2020": "Auto-évaluation 2020",
  "self-eval.confirmation.text":
    "L'auto-évaluation du programme qualité de Curacasa a été effectuée.",
  "self-eval.confirmation.title": "Attestation",
  "self-eval.criterion.name": "Critère {identifier}",
  "self-eval.disabled.member":
    "Le versement de la cotisation n’a pas encore été enregistré",
  "self-eval.disabled.nonMember":
    "Le premier versement pour le programme qualité n’a pas encore  été enregistré",
  "self-eval.description": "Description",
  "self-eval.glossary.title": "Glossaire",
  "self-eval.grade": "Note",
  "self-eval.grade.empty": "...",
  "self-eval.infos.alreadyTransmitted":
    "Vous avez déjà transmis votre auto-évaluation",
  "self-eval.infos.end2019.transmitted":
    "Le formulaire pour l’année 2020 sera accessible dès le 1er juillet. Toutes vos données sont actuellement sauvegardées et vous pourrez les retrouver à cette date.",
  "self-eval.infos.notSubmitted":
    "Une fois remplie, vous pouvez envoyer votre auto-évaluation avec le bouton Transmettre",
  "self-eval.infos.submitWarning":
    "Attention, une fois transmise, elle ne pourra plus être modifiée !",
  "self-eval.infos.successfullyTransmitted": "Transmise avec succès",
  "self-eval.infos.timeLimit": "Délai : 20 février 2022",
  "self-eval.instructions.title": "Instructions",
  "self-eval.notTransmitted": "Auto-évaluation non transmise",
  "self-eval.scale.title": "Échelle d'évaluation",
  "self-eval.seePDF": "PDF de l'auto-évaluation",
  "self-eval.seePDF.2019": "PDF de l'auto-évaluation 2019",
};
