import React from "react";
import {FormControl, Paper, withStyles} from "@material-ui/core";
import {Form} from "formik";
import CGrid from "./CGrid";
import {isWidthUp} from "@material-ui/core/withWidth";

const sectionStyles = theme => ({
  paper: {
    padding: theme.spacing.unit,

    // TODO : this is a workaround !
    margin: theme.spacing.unit / 2
  }
});

export const itemColumns = width => {
  if (isWidthUp('lg', width))
    return 6;
  if (isWidthUp('md', width))
    return 4;
  if (isWidthUp('sm', width))
    return 3;
  // xs
  return 2;
};

export const FormGrid = ({children, ...rest}) => (
  <CGrid component={Form} container item xs={12} {...rest}>
    {children}
  </CGrid>
);

export const RawSection = withStyles(sectionStyles)(({children, classes, ...rest}) => (
  <CGrid component={Paper} className={classes.paper} {...rest}>
    {children}
  </CGrid>
));

export const Section = ({children, ...rest}) => (
  <RawSection container spacing={8} {...rest}>
    {children}
  </RawSection>
);

export const WideGridItem = ({children, ...rest}) => (
  <CGrid item xs={12} {...rest}>
    {children}
  </CGrid>
);

export const StdGridItem = ({children, ...rest}) => (
  <CGrid item xs={12} sm={6} md={4} lg={3} {...rest}>
    {children}
  </CGrid>
);

export const FormButton = ({children, ...rest}) => (
  <CGrid item xs={12} md={6} lg={4} {...rest}>
    {children}
  </CGrid>
);

export const FormItemControl = ({children, ...rest}) => (
  <StdGridItem {...rest}>
    <FormControl fullWidth>
      {children}
    </FormControl>
  </StdGridItem>
);