import React, {Component} from "react";
import {Section, WideGridItem} from "../../utils/Layouts";
import NurseCard from "./NurseCard";
import * as ReactDOM from "react-dom";
import {H4} from "../../utils/Typography";

class FindNursesResults extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.scrollToResults = this.scrollToResults.bind(this);
  }

  scrollToResults() {
    window.scrollTo(0, this.state.offsetTop);
  }

  componentDidMount() {
    this.newlyMounted = true;
    const offsetTop = ReactDOM.findDOMNode(this).offsetTop;
    this.setState({offsetTop});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.newlyMounted)
      this.scrollToResults();
    this.newlyMounted = false;
  }

  render() {
    const {nurses} = this.props;

    return (
      <>
        {nurses.length
          ? nurses.slice(0)
            .sort((a, b) => {
              const lnA = a.lastName.toLowerCase();
              const lnB = b.lastName.toLowerCase();
              if (lnA < lnB)
                return -1;
              if (lnA > lnB)
                return 1;

              const fnA = a.firstName.toLowerCase();
              const fnB = b.firstName.toLowerCase();
              if (fnA < fnB)
                return -1;
              if (fnA > fnB)
                return 1;

              return 0;
            })
            .map(nurse => <NurseCard nurse={nurse} key={nurse.id}/>)
          : (
            <Section justify="center">
              <WideGridItem>
                <H4 id='general.noResult' defaultMessage='No result'/>
              </WideGridItem>
            </Section>
          )}
      </>
    );
  }

}

export default (FindNursesResults);