import React, {useEffect, useState} from "react";
import {CircularProgress} from "@material-ui/core";
import CGrid from "./CGrid";
import {useQuery} from "@apollo/react-hooks";

const SHOW_LOADING_DELAY = 300;

const CQuery = props => {
  const {children, query, ...otherProps} = props;
  const [showLoading, setShowLoading] = useState(false);
  const [showTimeout, setShowTimeout] = useState(0);

  useEffect(() => {
    if (!showTimeout)
      setShowTimeout(setTimeout(() => setShowLoading(false), SHOW_LOADING_DELAY));

    return () => {
      if (showTimeout)
        clearTimeout(showTimeout);
    }
  }, [showTimeout]);

  const {loading, error, ...rest} = useQuery(query, {...otherProps, partialRefetch: true});

  if (error)
    throw error;

  if (loading) {
    if (showLoading)
      return (
        <CGrid container alignItems="center" justify="center">
          <CircularProgress/>
        </CGrid>
      );

    return null;
  }

  if (!children)
    return null;

  return children({...rest});
};

export default (CQuery);