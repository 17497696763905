import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/it";

import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/dist/locale-data/de';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/dist/locale-data/it';

import React, {useState} from 'react';
import {createMuiTheme, CssBaseline, MuiThemeProvider} from "@material-ui/core";
import {purple, teal} from "@material-ui/core/colors";
import {ApolloProvider} from "@apollo/react-hooks";
import {IntlProvider} from "react-intl";
import {Route, Router, Switch} from "react-router";
import history from '../services/history';
import gql from "graphql-tag";
import Routes from "./Routes";
import {messages} from "../i18n/messages";
import {MuiPickersUtilsProvider} from "material-ui-pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import CQuery from "./utils/CQuery";
import {client} from "../services/client";
import AuthProvider from "./AuthProvider";
import ErrorBoundary from "./utils/ErrorBoundary";
import Callback from "./pages/Callback";
import Maintenance from "./Maintenance";

const theme = createMuiTheme({
  shadows: ["none"],
  palette: {
    primary: {
      main: '#00418e',
    },
    secondary: {
      main: '#83be56',
    }},
    typography: {
    button: {
      textTransform: "none"
    },
    useNextVariants: true
  },
  overrides: {
    MuiExpansionPanelDetails: {
      root: {
        paddingBottom: 8
      }
    },
    MUIDataTableBodyRow: {
      hover: {
        cursor: "pointer"
      }
    }
  }
});

const query = gql`
  query {
    permissions
    me {
      id
      appLanguage {
        id
      }
    }
    myInterventionGroup {
      id
      appLanguage {
        id
      }
    }
  }
`;

const validLanguages = ['de', 'fr', 'it'];
const defaultLanguage = 'de';
// noinspection JSDeprecatedSymbols
const browserLanguage = window.navigator.language || window.navigator.userLanguage;

const validateLocale = lang => {
  lang = lang && lang.substr(0, 2).toLowerCase();
  return validLanguages.includes(lang) && lang;
};

const getLocale = (manual, profile) =>
  validateLocale(manual)
  || validateLocale(profile)
  || validateLocale(browserLanguage)
  || defaultLanguage;

const App = () => {
  const [manualLanguage, setManualLanguage] = useState('');

  return (
    <MuiThemeProvider theme={theme} >
      {/* <Maintenance/> */}
      <CssBaseline/>
      <ErrorBoundary>
        <Router history={history}>
          <Switch>
            <Route path="/callback" exact render={props => <Callback {...props}/>}/>
            <Route render={() => (
              <AuthProvider>
                {({loggedIn, handleLogout}) => (
                  <ApolloProvider client={client}>
                    <CQuery query={query} fetchPolicy='network-only'>
                      {({data}) => {
                        const profileLanguage = (data.me && data.me.appLanguage && data.me.appLanguage.id)
                          || (data.myInterventionGroup && data.myInterventionGroup.appLanguage
                            && data.myInterventionGroup.appLanguage.id);
                        const locale = getLocale(manualLanguage, profileLanguage);

                        moment.locale(locale);

                        return (
                          <>
                            <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
                              <Switch>
                                <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
                                  <div lang={locale}>
                                    <Routes loggedIn={loggedIn} onLogout={handleLogout}
                                            setManualLanguage={setManualLanguage}/>
                                  </div>
                                </MuiPickersUtilsProvider>
                              </Switch>
                            </IntlProvider>
                          </>
                        );
                      }}
                    </CQuery>
                  </ApolloProvider>
                )}
              </AuthProvider>
            )}/>
          </Switch>
        </Router>
      </ErrorBoundary>
    </MuiThemeProvider>
  );
};

export default (App);
