import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {onError} from 'apollo-link-error';
import {ApolloLink, Observable} from 'apollo-link';
import {env} from "../env";
import {auth} from "./auth";
import {createUploadLink} from "apollo-upload-client";

const cache = new InMemoryCache();

const request = (operation) => {
  const token = auth.accessToken;
  if (token)
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`
      }
    });
};

const requestLink = new ApolloLink((operation, forward) =>
  new Observable(observer => {
    let handle;
    Promise.resolve(operation)
      .then(oper => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  })
);

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({graphQLErrors, networkError}) => {
      if (graphQLErrors && process.env.NODE_ENV !== 'production')
        graphQLErrors.map(({message, locations, path}) =>
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
          ),
        );
      if (networkError) console.error(`[Network error]: ${networkError}`);
    }),
    requestLink,
    createUploadLink({
      uri: env.apiUrl,
      credentials: 'same-origin'
    })
  ]),
  cache
});