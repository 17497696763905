import React from "react";
import {withStyles} from "@material-ui/core";
import gql from "graphql-tag";
import {Route, Switch} from "react-router";
import Error404 from "./pages/Error404";
import compose from "lodash.flowright";
import {adminPermissions} from "../services/permissions";
import Error403 from "./pages/Error403";
import Error401 from "./pages/Error401";
import Footer from "./partials/static/Footer";
import AdminDashboard from "./pages/AdminDashboard";
import CQuery from "./utils/CQuery";
import FindNurses from "./pages/FindNurses";
import CGrid from "./utils/CGrid";
import {Section} from "./utils/Layouts";
import ErrorBoundary from "./utils/ErrorBoundary";
import {loadable} from "../services/utils";

const Home = loadable(() => import("./pages/Home"));
const AdminPages = loadable(() => import("./pages/AdminPages"));
const Profile = loadable(() => import("./pages/Profile"));
const Nurse = loadable(() => import("./pages/Nurse"));
const AdminEditMenu = loadable(() => import("./pages/AdminEditMenu"));
const AdminCreateMenu = loadable(() => import("./pages/AdminCreateMenu"));
const AdminCreatePage = loadable(() => import("./pages/AdminCreatePage"));
const AdminEditPage = loadable(() => import("./pages/AdminEditPage"));
const AdminProfiles = loadable(() => import("./pages/AdminProfiles"));
const AdminEditNews = loadable(() => import("./pages/AdminEditNews"));
const AdminProfile = loadable(() => import("./pages/AdminProfile"));
const AdminCreateProfile = loadable(() => import("./pages/AdminCreateProfile"));
const Page = loadable(() => import("./pages/Page"));
const Header = loadable(() => import("./partials/static/Header"));
const Join = loadable(() => import("./pages/Join"));
const AdminExportData = loadable(() => import("./pages/AdminExportData"));
const AdminExportContacts = loadable(() => import("./pages/AdminExportContacts"));
const AdminSelfEvals = loadable(() => import("./pages/AdminSelfEvals"));
const AdminInterventionGroups = loadable(() => import("./pages/AdminInterventionGroups"));
const AdminCreateInterventionGroup = loadable(() => import("./pages/AdminCreateInterventionGroup"));
const AdminEditInterventionGroup = loadable(() => import("./pages/AdminEditInterventionGroup"));
const InterventionGroup = loadable(() => import("./pages/InterventionGroup"));

const styles = {
  root: {
    flexGrow: 1,
    minHeight: '100vh'
  },
  gridContent: {
    flexGrow: 1,
  }
};

const query = gql`
  query {
    permissions
  }
`;

const Routes = props => {
  const {classes, loggedIn, setManualLanguage, onLogout} = props;

  return (
    <CGrid container justify="center" className={classes.root}>
      <CQuery query={query}>
        {({data}) => {
          const {permissions} = data;
          const perms = permissions || [];
          const isAdmin = perms && perms.some(p => adminPermissions.includes(p));

          return (
            <>
            <Header loggedIn={loggedIn} onLogout={onLogout} setManualLanguage={setManualLanguage}/>
            <CGrid container item xs={12} lg={10} spacing={8} direction="column" justify='center'>
              <CGrid container alignContent="flex-start" className={classes.gridContent}>
                <ErrorBoundary>
                  <Section>
                    <Switch>
                      <Route path="/" exact render={props => <Home loggedIn={loggedIn} {...props}/>}/>
                      <Route path="/page/:id?" exact component={Page}/>
                      <Route path="/find" exact component={FindNurses}/>
                      <Route path="/join" exact component={Join}/>
                      <Route path="/nurse/:id" exact component={Nurse}/>
                      <Route path="/intervention-group/:id" exact component={InterventionGroup}/>
                      <Route path="/profile" exact component={loggedIn ? Profile : Error401}/>
                      <Route path="/profile/perso" exact component={loggedIn ? Profile : Error401}/>
                      <Route path="/profile/pro" exact component={loggedIn ? Profile : Error401}/>
                      <Route path="/profile/preview" exact component={loggedIn ? Profile : Error401}/>
                      <Route path="/profile/self-eval" exact component={loggedIn ? Profile : Error401}/>
                      {(!loggedIn) && <Route path="/admin" component={Error401}/>}
                      {(loggedIn && !isAdmin) && <Route path="/admin" component={Error403}/>}
                      <Route path="/admin" exact component={AdminDashboard}/>
                      <Route path="/admin/self-evals" exact
                             component={perms.includes('profiles') ? AdminSelfEvals : Error403}/>
                      <Route path="/admin/contacts" exact
                             component={perms.includes('contacts') ? AdminExportContacts : Error403}/>
                      <Route path="/admin/profiles" exact
                             component={(perms.includes('profiles') || perms.includes('self-eval') || perms.includes('contacts')) ? AdminProfiles : Error403}/>
                      <Route path="/admin/profile/new" exact
                             component={perms.includes('profiles') ? AdminCreateProfile : Error403}/>
                      <Route path="/admin/profile/:id" exact
                             component={perms.includes('profiles') || perms.includes('self-eval') || perms.includes('contacts') ? AdminProfile : Error403}/>
                      <Route path="/admin/pages" exact
                             component={perms.includes('pages') ? AdminPages : Error403}/>
                      <Route path="/admin/page/new/:menu" exact
                             component={perms.includes('pages') ? AdminCreatePage : Error403}/>
                      <Route path="/admin/page/:id" exact
                             component={perms.includes('pages') ? AdminEditPage : Error403}/>
                      <Route path="/admin/menu/new" exact
                             component={perms.includes('pages') ? AdminCreateMenu : Error403}/>
                      <Route path="/admin/menu/:id" exact
                             component={perms.includes('pages') ? AdminEditMenu : Error403}/>
                      <Route path="/admin/news" exact
                             component={perms.includes('pages') ? AdminEditNews : Error403}/>
                      <Route path="/admin/export" exact
                             component={perms.includes('profiles') ? AdminExportData : Error403}/>
                      <Route path="/admin/intervention-groups" exact
                             component={perms.includes('profiles') ? AdminInterventionGroups : Error403}/>
                      <Route path="/admin/intervention-group/new" exact
                             component={perms.includes('profiles') ? AdminCreateInterventionGroup : Error403}/>
                      <Route path="/admin/intervention-group/:id" exact
                             component={perms.includes('profiles') ? AdminEditInterventionGroup : Error403}/>
                      <Route component={Error404}/>
                    </Switch>
                  </Section>
                </ErrorBoundary>
              </CGrid>
            </CGrid>
            <Footer/>
            </>
          );
        }}
      </CQuery>
    </CGrid>
  );
};

export default compose(
  withStyles(styles)
)(Routes);
