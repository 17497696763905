import {useEffect, useState} from "react";
import {auth} from "../services/auth";

const JWT_REFRESH_INTERVAL = 60 * 60 * 1000; // every hour

const AuthProvider = props => {
  const {children, ...rest} = props;

  const [loggedIn, setLoggedIn] = useState(Boolean(auth.accessToken));
  const [authRenewed, setAuthRenewed] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(0);

  const renew = () => {
    auth.renewSession().then(() => setAuthRenewed(true));
  };

  useEffect(() => {
    if (!refreshInterval) {
      auth.tokenChangeCb = () => setLoggedIn(Boolean(auth.accessToken));
      renew();
      setRefreshInterval(setInterval(renew, JWT_REFRESH_INTERVAL));
    }

    return () => {
      if (refreshInterval)
        clearInterval(refreshInterval);
    }
  }, [refreshInterval]);

  const handleLogout = () => setAuthRenewed(false);

  if (!authRenewed)
    return null;

  return children({loggedIn, handleLogout, ...rest});
};

export default (AuthProvider);