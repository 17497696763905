import React from "react";
import {Grid} from "@material-ui/core";

const CGrid = props => {
  const {children, ...rest} = props;
  const {item, xs, sm, md, lg, xl, ...restContained} = rest;
  const {spacing} = restContained;
  const restContainer = {item, xs, sm, md, lg, xl};

  if (spacing !== undefined)
    return (
      <Grid container style={{padding: spacing / 2}} {...restContainer}>
        <Grid {...restContained} >
          {children}
        </Grid>
      </Grid>
    );

  return (
    <Grid {...rest}>
      {children}
    </Grid>
  )
};

export default (CGrid);