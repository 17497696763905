import {Typography, withStyles} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import React from "react";
import {nl2br} from "../../services/utils";

const styles = {
  bold: {
    fontWeight: 500,
    display: 'inline'
  },
  italic: {
    fontStyle: 'italic',
    display: 'inline'
  }
};

export const TypographyMessage = props => {
  const {children, noTypo, br, ...otherProps} = props;
  if (noTypo)
    return children ? (br ? nl2br(children) : children) : <FormattedMessage {...otherProps}/>;

  if (children)
    return (
      <Typography {...otherProps}>
        {br ? nl2br(children) : children}
      </Typography>
    );

  const {id, defaultMessage, values, description, ...rest} = otherProps;
  const msgProps = {id, defaultMessage, values, description};
  return (
    <Typography {...rest}>
      <FormattedMessage {...msgProps}/>
    </Typography>
  );
};

export const Text = props => <TypographyMessage {...props} component="span"/>;

export const Body1 = props => <TypographyMessage {...props} variant="body1"/>;

export const Paragraph = props => <TypographyMessage {...props} paragraph/>;

export const H1 = props => <TypographyMessage gutterBottom {...props} variant="h2"/>;

export const H2 = props => <TypographyMessage gutterBottom {...props} variant="h3"/>;

export const H3 = props => <TypographyMessage gutterBottom {...props} variant="h4"/>;

export const H4 = props => <TypographyMessage gutterBottom {...props} variant="h5"/>;

export const H5 = props => <TypographyMessage gutterBottom {...props} variant="h6"/>;

export const Subtitle1 = props => <TypographyMessage {...props} variant="subtitle1"/>;

export const Subtitle2 = props => <TypographyMessage {...props} variant="subtitle2"/>;

export const Bold = withStyles(styles)(({classes, ...props}) =>
  <TypographyMessage {...props} component="span" className={classes.bold}/>);

export const Italic = withStyles(styles)(({classes, ...props}) =>
  <TypographyMessage {...props} component="span" className={classes.italic}/>);

export const ButtonText = withStyles(styles)(({classes, ...props}) =>
  <TypographyMessage {...props} className={classes.noTextDecoration} noTypo/>);

export const Caption = props => <TypographyMessage {...props} variant="caption"/>;