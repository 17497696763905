import React, {useEffect, useState} from "react";
import {Redirect} from "react-router";
import {CircularProgress} from "@material-ui/core";
import Error from "../utils/Error";
import {auth} from "../../services/auth";

const Callback = props => {
  const {location} = props;

  const [valid, setValid] = useState(false);
  const [done, setDone] = useState(false);
  const [err, setErr] = useState(null);

  useEffect(() => {
    if (!/access_token|id_token|error/.test(location.hash)) {
      setValid(false);
      setDone(true);
    } else {
      auth.handleAuthentication(location.hash)
        .then(() => {
          setValid(true);
          setDone(true);
        })
        .catch(err => {
          setValid(true);
          setDone(true);
          setErr(err);
        });
    }
  }, [location]);

  if (!done)
    return <CircularProgress/>;

  if (!valid)
    return <Redirect to='/'/>;

  if (err)
    return <Error error={err}/>;

  return <Redirect to='/profile'/>;
};

export default (Callback);