import React from "react";
import moment from "moment";
import {auth} from "./auth";
import Loadable from "@loadable/component";
import Loading from "../components/utils/Loading";

export const withoutUndefNull = src =>
  Object.keys(src).reduce((obj, key) =>
      (src[key] !== undefined && src[key] !== null)
        ? Object.assign(obj, {[key]: src[key]})
        : obj,
    {});

export const mapObjectsToId = src =>
  Object.keys(src).reduce((obj, key) => {
    const item = src[key];
    return Object.assign(obj, {
      [key]: (typeof item === 'object' && !!item && item.id !== undefined
        && (Object.keys(item).length === 1
          || (Object.keys(item).length === 2 && Object.keys(item).includes('__typename'))))
        ? item.id
        : item
    })
  }, {});

export const reduceArraysToTrue = src =>
  Object.keys(src).reduce((obj, key) =>
    Object.assign(obj, {
      [key]: (Array.isArray(src[key]))
        ? reduceToTrue(src[key])
        : src[key]
    }), {});


export const reduceToTrue = list =>
  list.reduce((obj, item) => {
    if (typeof item === 'object' && process.env.NODE_ENV !== 'production')
      console.warn(`reducing an object to true : ${JSON.stringify(item)}`);
    return Object.assign(obj, {[item]: true})
  }, {});

export const mapTrue = obj =>
  obj && Object.keys(obj).filter(key => obj[key]).map(key => key);

export const mapReduceNoEmpty = src =>
  reduceArraysToTrue(mapObjectsToId(withoutUndefNull(src)));

export const currently = periods => {
  const now = Date.now();
  return periods.some(({started, stopped}) =>
    (started === null || Date.parse(started) <= now)
    && (stopped === null || Date.parse(stopped) >= now));
};

export const commonStyles = theme => ({
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    height: '100%'
  },
  picture: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  fullHeight: {
    height: '100%'
  },
  buttonIconLeft: {
    marginRight: theme.spacing.unit
  },
  buttonIconRight: {
    marginLeft: theme.spacing.unit
  },
  noTextDecoration: {
    textDecoration: 'none',
    textDecorationLine: 'none'
  },
  minimalMargin: {
    margin: theme.spacing.unit / 2
  }
});

export const nl2br = text => (
  typeof text === 'string'
    ? <>
      {text.split('\n').map((item, index) =>
        <React.Fragment key={index}>{item}<br/></React.Fragment>)}
    </>
    : text
);

export const deepCopyData = obj => JSON.parse(JSON.stringify(obj));

export const formatCode = code => {
  if (!code || typeof code !== 'string' || code.length !== 16)
    return code;

  return code.match(/.{4}/g).join('-');
};

export const formatPeriods = periods => (
  periods && Array.isArray(periods)
    ? periods.map(({id, started, stopped}) => ({
      id,
      started: started ? moment(started).format('YYYY-MM-DD') : null,
      stopped: stopped ? moment(stopped).format('YYYY-MM-DD') : null
    }))
    : periods
);

export const validateFn = (formatMsg, fn) => value => {
  const [id, defaultMessage, values] = fn(value) || [];

  if (id)
    return formatMsg({id: `error.${id}`, defaultMessage}, values);
};

export const sortPosition = ({position: a}, {position: b}) => a - b;

export const boolToStr = bool => bool === true ? '1' : (bool === false ? '0' : bool);

export const fetchImgWithAuth = url => {
  const token = auth.accessToken;
  const headers = token ? {'Authorization': `Bearer ${token}`} : {};
  return fetch(url, {headers}).then(res => res.blob());
};

export const loadable = loader => Loadable(loader, {fallback: <Loading/>});

export const downloadCsv = (fileName, input, init) => {
  fetch(input, init)
    .then(response => response.blob())
    .then(blob => {
      const objectUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      document.body.appendChild(anchor);
      anchor.href = objectUrl;
      anchor.download = fileName;
      anchor.click();

      const userAgent = window.navigator.userAgent;
      if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Edge") > -1)
        // Delay the revocation on IE/Edge, as it blocks the download otherwise
        setTimeout(() => window.URL.revokeObjectURL(objectUrl), 10000);
      else
        window.URL.revokeObjectURL(objectUrl);
    })
};

export const buildTableOptions = (formatMessage, onRowClick) => ({
  filter: false,
  search: false,
  rowsPerPage: 50,
  rowsPerPageOptions: [10, 30, 50],
  print: false,
  download: false,
  selectableRows: "none",
  onRowClick,

  // untranslated strings are not in use
  textLabels: {
    body: {
      noMatch: formatMessage({id: "general.noResult", defaultMessage: "Sorry, no matching records found"}),
      toolTip: formatMessage({id: "adminProfiles.table.sort", defaultMessage: "Sort"}),
    },
    pagination: {
      next: formatMessage({id: "adminProfiles.table.nextPage", defaultMessage: "Next Page"}),
      previous: formatMessage({id: "adminProfiles.table.previousPage", defaultMessage: "Previous Page"}),
      rowsPerPage: formatMessage({id: "adminProfiles.table.rowsPerPage", defaultMessage: "Rows per page:"}),
      displayRows: formatMessage({id: "adminProfiles.table.of", defaultMessage: "of"}),
    },
    toolbar: {
      search: ({id: "general.action.search", defaultMessage: "Search"}),
      downloadCsv: "Download CSV",
      print: "Print",
      viewColumns: formatMessage({
        id: "adminProfiles.table.viewColumns.toolbar",
        defaultMessage: "View Columns"
      }),
      filterTable: "Filter Table",
    },
    filter: {
      all: "All",
      title: "FILTERS",
      reset: "RESET",
    },
    viewColumns: {
      title: formatMessage({id: "adminProfiles.table.viewColumns.title", defaultMessage: "Show Columns"}),
      titleAria: formatMessage({
        id: "adminProfiles.table.viewColumns.titleAria",
        defaultMessage: "Show/Hide Table Columns"
      }),
    },
    selectedRows: {
      text: "row(s) selected",
      delete: "Delete",
      deleteAria: "Delete Selected Rows",
    },
  }
});
