// noinspection SpellCheckingInspection
export default {
  "401.description": "Accesso negato: deve effettuare l‘accesso",
  "403.description": "Accesso negato: diritti insufficienti",
  "404.description": "Pagina non trovata",
  "actions.adminLink": "Amministrazione",
  "actions.findFreelance": "Ricercare un'infermiera",
  "actions.join": "Iscrizione membro",
  "actions.login": "myCuracasa",
  "actions.newcuracasa": "Ricercare un'infermiera:",
  "actions.newcuracasalink": "disponibile qui",
  "actions.logout": "Disconnessione",
  "actions.myProfile": "myCuracasa",
  "addContact.comment": "Commento",
  "addContact.duration": "Durata (minuti)",
  "addContact.type1": "Tipo 1",
  "addContact.type2": "Tipo 2",
  "adminContacts.seePDF": "PDF dei contatti",
  "adminInterventionGroup.confirmDeleteGroup":
    "Sei sicuro di voler cancellare questo gruppo di intervento?",
  "adminInterventionGroups.addButton": "Aggiungiare un gruppo di intervento",
  "adminMenu.name": "Cognome",
  "adminMenu.position": "Posizione",
  "adminMenu.singlePage": "Menu a pagina singola",
  "adminNews.position": "Posizione",
  "adminNews.title": "Titolo",
  "adminNews.text": "Testo",
  "adminNews.link": "Link",
  "adminPage.child": "Sottopagina",
  "adminPage.content": "Contenuto",
  "adminPage.menu": "Menu",
  "adminPage.position": "Posizione",
  "adminPage.title": "Titolo",
  "adminPages.addMenu": "Aggiungiare un menu",
  "adminPages.addPage": "Aggiungiare una pagina",
  "adminPages.confirmDeleteMenu": "Sei sicuro di voler cancellare questo menu?",
  "adminPages.confirmDeletePage":
    "Sei sicuro di voler cancellare questa pagina?",
  "adminPages.noPage": "Nessuna pagina in questo menu",
  "adminProfile.tabs.contacts": "Contatti",
  "adminProfile.tabs.data": "Dati",
  "adminProfiles.addButton": "Creare un profilo",
  "adminProfiles.freelanceSince": "Independente dal",
  "adminProfiles.inputLabel": "Ricerca...",
  "adminProfiles.search": "Cerca un profilo",
  "adminProfiles.table.nextPage": "Pagina successiva",
  "adminProfiles.table.of": "di",
  "adminProfiles.table.previousPage": "Pagina precedente",
  "adminProfiles.table.rowsPerPage": "Righe per pagina",
  "adminProfiles.table.sort": "Ordinare",
  "adminProfiles.table.viewColumns.toolbar": "Vedi le colonne",
  "adminProfiles.table.viewColumns.title": "Mostra colonne",
  "adminProfiles.table.viewColumns.titleAria": "Mostra/nascondi colonne",
  "adminSelfEvals.asiNumbers": "Numeri ASI",
  "adminSelfEvals.title": "Gestione dell'accesso all'autovalutazione",
  "contactDetails.duration": "{number} minuti",
  "contactsPDF.history": "Cronologia dei contatti",
  "createMenu.title": "Creare un menu",
  "createPage.title": "Creare una pagina",
  "dashboard.contactsLink": "Esportazione di contatti",
  "dashboard.exportLink": "Esportazione di dati",
  "dashboard.interventionGroupsLinks": "Visuallizare i gruppi di intervento",
  "dashboard.newsLink": "Gestire le news",
  "dashboard.pagesLink": "Visualizzare menu e pagina",
  "dashboard.profilesLink": "Visualizzare i profili",
  "dashboard.selfEvalsLink": "Accesso all'autovalutazione",
  "dashboard.title": "Albo",
  "editMenu.title": "Modificare un menu",
  "editPage.title": "Modificare una pagina",
  "error.address.streetOrPoBoxRequired": "Via o casella postale richiesta",
  "error.integer": "Deve essere un numero intero",
  "error.invalidAsiNumber": "Numero ASI non valido : {number}",
  "error.required": "Richiesto",
  "error.tooHigh": "Troppo alto",
  "error.tooLong": "Troppo lungo",
  "error.tooLow": "Troppo basso",
  "error.tooShort": "Troppo corto",
  "error.unspecified":
    "Si è verificato un errore sconosciuto. Prova ad aggiornare",
  "export.appLanguages.any": "Qualsiasi lingua",
  "export.curacasaMember.any": "Membro o no",
  "export.curacasaMember.no": "Non un membro di Curacasa",
  "export.curacasaMember.yes": "Membro di Curacasa",
  "export.domain": "Specialità",
  "export.domains.any": "Qualsiasi specialità",
  "export.interventionCanton": "Cantone di intervento",
  "export.interventionCanton.any": "Qualsiasi cantone",
  "export.title": "Esportazione dei dati degli infermieri attivi",
  "exportContacts.title": "Esportare la cronologia dei contatti",
  "find.advanced": "Ricerca ampliata",
  "find.cantonAdmission.any": "Qualsiasi cantone",
  "find.cantonAdmission": "Autorizzazione cantonale",
  "find.domain": "Specialità",
  "find.domains.any": "Qualsiasi specialità",
  "find.interventionDelay.any": "Qualsiasi tempo di intervento",
  "find.interventionDelay.available": "Disponibilità immediata",
  "find.interventionDelay.max2Months": "Massimo 2 mesi di attesa",
  "find.interventionDelay.max3Weeks": "Massimo 3 settimane di attesa",
  "find.interventionDelay.unavailable": "Nessuna disponibilità",
  "find.label.any": "Con o senza label",
  "find.label.with": "Con label",
  "find.label.without": "Sensa label",
  "find.practiceArea": "Regione",
  "find.practiceArea.any": "Qualsiasi regione",
  "find.practiceLanguage": "Lingua",
  "find.practiceLanguages.any": "Qualsiasi lingua",
  "find.simple": "Ricerca semplice",
  "find.title": "Ricercare infermieri",
  "footer.address.poBox": "Casella postale 1010",
  "footer.address.town": "3006 Berna",
  "footer.curacasa.openingHours.1": "Lu. a Ve. : 09.00-12.00",
  "footer.curacasa.openingHours.2": "Lu. e Me. : 13.30-17.00",
  "footer.link.elog": "https://www.e-log.ch/it/",
  "footer.link.hin": "https://www.hin.ch/fr/produkte/hin-curacasa/",
  "footer.support.openingHours": "Lu. a Me. : 17.00-18.00",
  "footer.support.title": "Assistenza myCuracasa e e-log",
  "general.action.back": "Ritorno",
  "general.action.cancel": "Annullare",
  "general.action.choose": "Scegliere",
  "general.action.clear": "Cancellare",
  "general.action.confirm": "Confermare",
  "general.action.download": "Scaricare",
  "general.action.save": "Registrare",
  "general.action.search": "Ricercare",
  "general.action.send": "Inviare",
  "general.action.transmit": "Inviare",
  "general.address": "Indirizzo",
  "general.birthDate": "Data di nascita",
  "general.date": "Data",
  "general.date.invalid": "Data non valida",
  "general.date.today": "Oggi",
  "general.email": "Email",
  "general.enable": "Attivare",
  "general.firstName": "Nome",
  "general.language": "Lingua",
  "general.lastName": "Cognome",
  "general.month": "Mese",
  "general.name": "Nome",
  "general.no": "No",
  "general.noResult": "Nessun risultato",
  "general.nResults": "{number} risultati",
  "general.phone": "Telefono",
  "general.saved": "Registrato",
  "general.yes": "Si",
  "interventionGroup.comment": "Commento",
  "interventionGroup.members.add": "Aggiungi un membro",
  "interventionGroup.members.confirmRemove":
    "Sei sicuro di voler rimuovere questo membro ?",
  "interventionGroup.members.instructions":
    "Insecrire un numero ASI (ad es. 12345.1)",
  "interventionGroup.members.noMember": "Nessun membro",
  "interventionGroup.members.notFound":
    "Questo numero ASI non è valido o l'infirmiere ha già un gruppo di intervento",
  "interventionGroup.members.promptConfirm":
    "Questo numero ASI appartiene a {name}. È corretto ?",
  "interventionGroup.members.title": "Membri",
  "interventionGroup.numMembers": "{number} membri",
  "join.comment": "Commento",
  "join.fee.amount": "Quota associativa",
  "join.fee.info":
    "La quota associativa è decrescente in base al mese di iscrizione",
  "join.fee.title": "Quota associativa",
  "join.success.text":
    "La tua richiesta è stata inviata, riceverai una conferma via e-mail. La segreteria vi contatterà per finalizzare la vostra iscrizione.",
  "join.success.title": "Inviare con successo",
  "join.title": "Iscrizione membro",
  "linkProfile.codeLabel": "Codice",
  "linkProfile.contact":
    "Se ha delle domande ci contatti all‘indirizzo {address}",
  "linkProfile.failed":
    "Errore in sede d‘utilizzo del codice. Voglia riprovare",
  "linkProfile.notFound":
    "Nessun proflilo trovato: il codice non è valido o è già in uso",
  "linkProfile.promptCode":
    "Non ha ancora un profilo. Se ha ricevuto un codice lo inserisca qui",
  "linkProfile.promptConfirm":
    "Questo profilo appartiene a {name}, confermate di essere voi?",
  "linkProfile.success": "Profilo collegato correttamente. Ora viene caricato",
  "linkProfile.title": "Collega un profilo",
  "nurse.action.preview": "Anteprima del profilo",
  "nurse.asi": "Numero ASI",
  "nurse.asiMember": "Membro dell'ASI",
  "nurse.cantonAdmissions": "Autorizzazioni di pratica cantonale",
  "nurse.certification": "Label qualità Curacasa",
  "nurse.certification.2020": "Label qualità 2022-2023",
  "nurse.comment": "Commento pubblico",
  "nurse.company": "Gruppo / Team",
  "nurse.confirmLeaveInterventionGroup":
    "Siete sicuri di voler lasciare il vostro gruppo d'intervento ?",
  "nurse.curacasaMember": "Membro di Curacasa",
  "nurse.districts": "Regione di intervento",
  "nurse.domains": "Specialità",
  "nurse.employeePercentage": "Percentuale come impiegato %",
  "nurse.freelancePercentage": "Percentuale quale indipendente %",
  "nurse.interventionDelay": "Tempo di intervento",
  "nurse.interventionDelay.unspecified": "Non specificato",
  "nurse.interventionGroup": "Gruppo di intervento",
  "nurse.interventionGroup.none": "Nessun gruppo di intervento",
  "nurse.interventionGroup.withName": "Gruppo di intervento : {name}",
  "nurse.interventionGroup.withoutName": "Gruppo di intervento : ",
  "nurse.join": "Iscrizione membro",
  "nurse.leaveInterventionGroup": "Lasciare il gruppo",
  "nurse.notCertified": "Nensa certificato",
  "nurse.period.add": "Aggiungi un periodo",
  "nurse.period.end": "Fine",
  "nurse.period.start": "Inizio",
  "nurse.picture": "Foto",
  "nurse.practiceLanguages": "Lingue d‘intervento",
  "nurse.president": "Presidente-ssa",
  "nurse.rcc": "Numero RCC",
  "nurse.section": "Sezione ASI",
  "nurse.selfEvalEnabled": "Autovalutazione accessibile",
  "nurse.specialMember": "Membro del comitato o dipendente",
  "nurse.visible": "Profilo visualizzato pubblicamente",
  "nurse.visible.tooltip":
    "Richiede almeno una autorizzazione d‘esercizio  cantonale",
  "nurse.psychiatryExperience": "APsy",
  "nurse.psychiatryExperienceArt":
    "Attività pratica di due anni nel ramo della psichiatria al senso dell'art. 7 cpv. 2bis lett. b dell'Opre",
  "nurse.psychiatryAsterix": "APsy *: ",
  "nurse.psychiatryAsterixContent":
    "APsy *: Attività pratica di due anni nel ramo della psichiatria al senso dell'art. 7 cpv. 2bis lett. b dell'Opre",
  "nurse.website": "Sito  web",
  "nurseDetails.notFound": "Infermiere non trovato",
  "profile.address.add": "Aggiungere un indirizzo",
  "profile.address.canton": "Cantone",
  "profile.address.country": "Paese",
  "profile.address.isContact": "Indirizzo di contatto",
  "profile.address.poBox": "Casella postale",
  "profile.address.public": "Indirizzo visionabile pubblicamente",
  "profile.address.setContact": "Imposta come indirizzo di contatto",
  "profile.address.street": "Via e numero",
  "profile.address.town": "Luogo",
  "profile.address.type": "Tipo",
  "profile.address.zip": "NAP",
  "profile.addresses": "Indirizzi",
  "profile.email.add": "Aggiungere un indirizzo mail",
  "profile.email.address": "Indirizzo",
  "profile.email.isContact": "e.mail di contatto",
  "profile.email.public": "e.mail visibile pubblicamente",
  "profile.email.setContact": "Imposta come mail di contatto",
  "profile.email.type": "Tipo",
  "profile.emails": "e.mails",
  "profile.notNurse": "Non è un'infermiera",
  "profile.phone.add": "Aggiungere un numero telefonico",
  "profile.phone.isContact": "Telefono di contatto",
  "profile.phone.number": "Numero",
  "profile.phone.public": "Telefono visibile pubblicamente",
  "profile.phone.setContact": "Imposta come numero di contatto",
  "profile.phone.type": "Tipo",
  "profile.phones": "Telefoni",
  "profile.salutation": "Titolo",
  "profile.tabs.personalInfos": "Informazioni personali",
  "profile.tabs.preview": "Anteprima",
  "profile.tabs.professionalInfos": "Informazioni professionali",
  "profile.tabs.selfEval": "Autovalutazione",
  "profile.type": "Tipo di profilo",
  "profileInfos.latestContacts": "Ultimi contatti",
  "self-eval.2019": "Autovalutazione 2019",
  "self-eval.2020": "Autovalutazione 2020",
  "self-eval.confirmation.text":
    "L’autovalutazione del programma di qualità di Curacasa e statta realizzata.",
  "self-eval.confirmation.title": "Attestato",
  "self-eval.criterion.name": "Criterio {identifier}",
  "self-eval.disabled.member":
    "Il pagamento della quota associativa non è stato ancora registrato",
  "self-eval.disabled.nonMember":
    "Il primo pagamento per il programma di qualità non è stato ancora registrato",
  "self-eval.description": "Descrizione",
  "self-eval.glossary.title": "Glossario",
  "self-eval.grade": "Scala",
  "self-eval.grade.empty": "...",
  "self-eval.infos.alreadyTransmitted": "Ha già inviato la sua autovalutazione",
  "self-eval.infos.end2019.transmitted":
    "Il modulo per l’anno 2020 sarà disponibile a partire dal 1o luglio. Tutti i suoi dati sono attualmente sottoposti a backup e potrà recuperarli in tale data.",
  "self-eval.infos.notSubmitted":
    "Una volta completato, puoi inviare l'autovalutazione con il pulsante Inviare",
  "self-eval.infos.submitWarning":
    "Attenzione, una volta inviata, non può essere modificata!",
  "self-eval.infos.successfullyTransmitted": "Trasmesso con successo",
  "self-eval.infos.timeLimit": "Termine : 20 febbraio 2021",
  "self-eval.instructions.title": "Istruzioni",
  "self-eval.notTransmitted": "Autovalutazione non transmessa",
  "self-eval.scale.title": "Scala per valutare",
  "self-eval.seePDF": "PDF dell'autovalutazione",
  "self-eval.seePDF.2019": "PDF dell'autovalutazione 2019",
};
