import React, {Component} from "react";
import Error from "./Error";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {errorOccurred: false};
  }

  static getDerivedStateFromError(error) {
    return {errorOccurred: true, error};
  }

  componentDidCatch(error, errorInfo) {
    this.setState({errorOccurred: true, error: {error, errorInfo}});
  }

  render() {
    const {errorOccurred, error} = this.state;
    const {children} = this.props;

    if (errorOccurred)
      return <Error error={error}/>;

    return children;
  }
}

export default (ErrorBoundary);