import React from "react";
import gql from "graphql-tag";
import CQuery from "../utils/CQuery";
import {Link} from "react-router-dom";
import {Button} from "@material-ui/core";
import CGrid from "../utils/CGrid";
import {ButtonText, H1} from "../utils/Typography";

const query = gql`
  query {
    permissions
  }
`;

const AdminDashboard = () =>
  <CQuery query={query}>
    {({data: {permissions}}) => {
      const perms = permissions || [];

      return (
        <CGrid container justify="center" alignItems="center" spacing={16}>
          <CGrid item>
            <H1 id='dashboard.title' defaultMessage='Dashboard'/>
          </CGrid>
          <CGrid container item justify="center" alignItems="center" spacing={8}>
            {(perms.includes('profiles') || perms.includes('self-eval')) &&
            <CGrid item>
              <Button variant="outlined" component={Link} to='/admin/profiles'>
                <ButtonText id='dashboard.profilesLink' defaultMessage='List profiles'/>
              </Button>
            </CGrid>}
            {perms.includes('profiles') &&
            <>
              <CGrid item>
                <Button variant="outlined" component={Link} to='/admin/intervention-groups'>
                  <ButtonText id='dashboard.interventionGroupsLinks' defaultMessage='List intervention groups'/>
                </Button>
              </CGrid>
              <CGrid item>
                <Button variant="outlined" component={Link} to='/admin/export'>
                  <ButtonText id='dashboard.exportLink' defaultMessage='Export data'/>
                </Button>
              </CGrid>
              <CGrid item>
                <Button variant="outlined" component={Link} to='/admin/self-evals'>
                  <ButtonText id='dashboard.selfEvalsLink' defaultMessage='Manage self-evals access'/>
                </Button>
              </CGrid>
            </>}
            {perms.includes('contacts') &&
            <CGrid item>
              <Button variant="outlined" component={Link} to='/admin/contacts'>
                <ButtonText id='dashboard.contactsLink' defaultMessage='Export contacts'/>
              </Button>
            </CGrid>}
            {perms.includes('pages') &&
            <>
              <CGrid item>
                <Button variant="outlined" component={Link} to='/admin/pages'>
                  <ButtonText id='dashboard.pagesLink' defaultMessage='List menus and pages'/>
                </Button>
              </CGrid>
              <CGrid item>
                <Button variant="outlined" component={Link} to='/admin/news'>
                  <ButtonText id='dashboard.newsLink' defaultMessage='Manage news'/>
                </Button>
              </CGrid>
            </>}
          </CGrid>
        </CGrid>
      );
    }}
  </CQuery>;

export default AdminDashboard;
