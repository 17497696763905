import React from "react";
import { Link } from "react-router-dom";
import { Section, WideGridItem } from "../../utils/Layouts";
import { env } from "../../../env";
import picturePlaceholder from "../../../assets/picture_placeholder.png";
import certifiedLabel from "../../../assets/certified_img.png"
import CGrid from "../../utils/CGrid";
import compose from "lodash.flowright";
import { withStyles } from "@material-ui/core";
import { Caption, Subtitle2, Bold } from "../../utils/Typography";

const styles = {
  nursePicture: {
    maxWidth: '100%',
    maxHeight: '7.5rem'
  },
  labelPicture: {
    maxWidth: '100%',
    maxHeight: '4rem'
  }
};

const NurseCard = props => {
  const { nurse, classes } = props;
  const { id, firstName, lastName, pictureUrl, certified, phone, member, company, psychiatry } = nurse;

  return (
    <Section item xs={12} md={6} lg={4}>
      <CGrid container component={Link} to={`/nurse/${id}`} target='_blank' rel='noopener' spacing={8}>
        <CGrid container item xs={3} md={3} alignContent="center" alignItems="center">
          {pictureUrl
            ? <img alt={`${firstName} ${lastName}`} src={`${env.filesUrl}${pictureUrl}`}
              className={classes.nursePicture} />
            : <img alt="placeholder" src={picturePlaceholder} className={classes.nursePicture} />}
        </CGrid>
        <CGrid container item xs={7} md={7} alignContent="center">
          <WideGridItem>
            <Subtitle2 noWrap>
              {`${firstName} ${lastName}`}
            </Subtitle2>
          </WideGridItem>
          {company
            ? <WideGridItem><Caption>{company}</Caption></WideGridItem>
            : null}
          {phone && phone.number
            ? <WideGridItem><Caption>{phone.number}</Caption></WideGridItem>
            : null}
          {member
            ? (
              <WideGridItem>
                <Caption id='nurse.curacasaMember' defaultMessage='Curacasa member' />
              </WideGridItem>
            )
            : null}
          {psychiatry ?
            <WideGridItem>
              <Bold id='nurse.psychiatryAsterix' defaultMessage='Psychiatry experience*' />
              <Bold id='general.yes' defaultMessage='Psychiatry experience*' />
            </WideGridItem>
            : null}
        </CGrid>
        <CGrid container item xs={2} md={2} alignContent="center" alignItems="center">
          {certified
            ? <img alt="certified" src={certifiedLabel} className={classes.labelPicture} />
            : null}
        </CGrid>
      </CGrid>
    </Section>
  );
};

export default compose(
  withStyles(styles)
)(NurseCard);