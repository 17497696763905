import React from "react";
import {CircularProgress} from "@material-ui/core";
import CGrid from "./CGrid";

const Loading = props => {
  const {pastDelay} = props;

  if (pastDelay)
    return (
      <CGrid container alignItems="center" justify="center">
        <CircularProgress/>
      </CGrid>
    );

  return null;
};

export default (Loading);