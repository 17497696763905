import React from "react";
import compose from "lodash.flowright";
import { Hidden, withStyles } from "@material-ui/core";
import CGrid from "../../utils/CGrid";
import { Body1, Caption } from "../../utils/Typography";
import hinLogo from "../../../assets/hin.png";
import elogLogo from "../../../assets/elog.jpg";
import { injectIntl } from "react-intl";
import { WideGridItem } from "../../utils/Layouts";
import Button from "@material-ui/core/Button";
import { auth } from "../../../services/auth";

const styles = (theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing.unit * 10,
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  footerPartMdUp: {
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
  },
  footerPartSmDown: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  text: {
    color: theme.palette.primary.contrastText,
  },
  logo: {
    height: 70,
    marginBottom: theme.spacing.unit,
  },
  alignStart: {
    textAlign: "start",
  },
  alignCenter: {
    textAlign: "center",
  },
  alignEnd: {
    textAlign: "end",
  },
});

const Footer = (props) => {
  const {
    classes,
    intl: { formatMessage },
  } = props;

  const curacasaAddress = (
    <>
      <Body1 className={classes.text}>Curacasa</Body1>
      <Body1 className={classes.text}>Elfenstrasse 19</Body1>
      <Body1
        className={classes.text}
        id="footer.address.poBox"
        defaultMessage="PO Box 1010"
      />
      <Body1
        className={classes.text}
        id="footer.address.town"
        defaultMessage="3006 Bern"
      />
      <Button onClick={auth.login} className={classes.text}>
        login
      </Button>
    </>
  );

  const curacasaContactInfos = (
    <>
      <Body1 className={classes.text}>031 356 90 03</Body1>
      <Body1 className={classes.text}>
        <a href="mailto:info@curacasa.ch" className={classes.text}>
          info@curacasa.ch
        </a>
      </Body1>
      <Body1
        className={classes.text}
        id="footer.curacasa.openingHours.1"
        defaultMessage="Mo. - Fr. : 09.00-12.00"
      />
      <Body1
        className={classes.text}
        id="footer.curacasa.openingHours.2"
        defaultMessage="Mo. + We. : 13.30-17.00"
      />
    </>
  );

  const support = (
    <>
      <Body1
        className={classes.text}
        id="footer.support.title"
        defaultMessage="myCuracasa and e-log support"
      />
      <Body1 className={classes.text}>076 243 77 66</Body1>
      <Body1 className={classes.text}>
        <a href="mailto:e-log@curacasa.ch" className={classes.text}>
          e-log@curacasa.ch
        </a>
      </Body1>
      <Body1
        className={classes.text}
        id="footer.support.openingHours"
        defaultMessage="We. + Th. : 14.00-16.00"
      />
    </>
  );

  const logos = (
    <>
      <div>
        <a
          href={formatMessage({
            id: "footer.link.elog",
            defaultMessage: "https://www.e-log.ch/",
          })}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={elogLogo} className={classes.logo} alt="e-log" />
        </a>
      </div>
      <div>
        <a
          href={formatMessage({
            id: "footer.link.hin",
            defaultMessage: "https://www.hin.ch/produkte/hin-curacasa/",
          })}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={hinLogo} className={classes.logo} alt="HIN" />
        </a>
      </div>
    </>
  );

  return (
    <CGrid
      component="footer"
      container
      className={classes.footer}
      justify="center"
      alignContent="space-around"
    >
      <Hidden smDown>
        <CGrid
          item
          md={3}
          className={`${classes.footerPartMdUp} ${classes.alignStart}`}
        >
          {curacasaAddress}
        </CGrid>
        <CGrid
          item
          md={3}
          className={`${classes.footerPartMdUp} ${classes.alignStart}`}
        >
          {curacasaContactInfos}
        </CGrid>

        <CGrid item md={3} className={`${classes.footerPartMdUp} ${classes.alignEnd}`}>
        </CGrid>
        <CGrid item md={3} className={`${classes.footerPartMdUp} ${classes.alignEnd}`}>

          {logos}
        </CGrid>
      </Hidden>
      <Hidden mdUp>
        <CGrid
          item
          xs={12}
          className={`${classes.footerPartSmDown} ${classes.alignCenter}`}
        >
          {curacasaAddress}
        </CGrid>
        <CGrid
          item
          xs={12}
          className={`${classes.footerPartSmDown} ${classes.alignCenter}`}
        >
          {curacasaContactInfos}
        </CGrid>

        <CGrid item xs={12} className={`${classes.footerPartSmDown} ${classes.alignCenter}`}>
        </CGrid>
        <CGrid item xs={12} className={`${classes.footerPartSmDown} ${classes.alignCenter}`}>

          {logos}
        </CGrid>
      </Hidden>
      <WideGridItem className={classes.alignCenter}>
        <Caption className={classes.text}>
          Made by{" "}
          <a
            className={classes.text}
            href="https://www.donatien-bdr.ch"
            rel="noopener noreferrer"
            target="_blank"
          >
            Donatien Burin des Roziers
          </a>
        </Caption>
      </WideGridItem>
    </CGrid>
  );
};

export default compose(injectIntl, withStyles(styles))(Footer);
