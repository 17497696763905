const DefaultCreateAddress = {
  type: 1,
  country: 'CH',
  canton: 'BE',
  poBox: '',
  public: false,
  contact: false,
  street: '',
  zip: '',
  town: ''
};

const DefaultEditAddress = DefaultCreateAddress;

const DefaultCreatePhone = {
  type: 1,
  number: '',
  public: false,
  contact: false
};

const DefaultEditPhone = DefaultCreatePhone;

const DefaultCreateEmail = {
  type: 1,
  address: '',
  public: false,
  contact: false
};

const DefaultEditEmail = DefaultCreateEmail;

const DefaultCreateNurse = {
  birthDate: null,
  employeePercentage: '',
  freelancePercentage: '',
  section: 1,
  website: '',
  company: '',
  interventionDelay: 0,
  comment: '',
  practiceLanguages: {},
  cantonAdmissions: {},
  domains: {},
  districts: {},
  certified: false,
  psychiatry: false,
  selfEvalEnabled: false,
  member: [],
  asiMember: [],
  specialMember: [],
  president: [],
  rcc: '',
  asi: ''
};

const DefaultEditNurse = DefaultCreateNurse;

const DefaultCreateProfile = {
  type: 2,
  salutation: 'Frau',
  firstName: '',
  lastName: '',
  appLanguage: 'DE',
  addresses: [Object.assign({}, DefaultCreateAddress, {contact: true})],
  emails: [],
  phones: [],
  nurse: DefaultCreateNurse
};

const DefaultOwnProfile = {
  type: 3,
  salutation: 'Herr',
  firstName: '',
  lastName: '',
  appLanguage: 'DE',
  addresses: [],
  emails: [],
  phones: []
};

const DefaultFindNursesCriteria = {
  name: '',
  rcc: '',
  company: '',
  cantonAdmission: 0,
  domain: 0,
  canton: 0,
  districts: {},
  certified: 0,
  practiceLanguage: 0,
  interventionDelay: 0,
  interventionGroup: ''
};

const DefaultSelfEval = {
  published: false,
  answers: []
};

const DefaultCriterionAnswer = {
  description: '',
  grade: -1
};

const DefaultCreateMenu = {
  position: 1,
  name: {
    FR: '',
    DE: '',
    IT: ''
  },
  singlePage: false
};

const DefaultEditMenu = DefaultCreateMenu;

const DefaultCreatePage = {
  position: 1,
  menu: 1,
  child: false,
  title: {
    FR: '',
    DE: '',
    IT: ''
  },
  content: {
    FR: '',
    DE: '',
    IT: ''
  }
};

const DefaultEditPage = DefaultCreatePage;

const DefaultEditNews = {
  id1: {
    position: 1,
    title: '',
    text: '',
    url: ''
  },
  id2: {
    position: 2,
    title: '',
    text: '',
    url: ''
  },
  id3: {
    position: 3,
    title: '',
    text: '',
    url: ''
  }
};

const DefaultFindProfilesInput = {
  input: ""
};

const DefaultEditProfile = DefaultCreateProfile;

const DefaultCreatePeriod = () => ({
  started: new Date(),
  stopped: null
});

const DefaultExportDataFilter = {
  curacasaMember: 0,
  language: 0,
  sections: {},
  cantonAdmissions: {},
  label: 0,
  interventionCanton: 0,
  domain: 0
};

const DefaultJoinInput = {
  firstName: '',
  lastName: '',
  email: '',
  language: 'DE',
  address: '',
  phone: '',
  asi: '',
  rcc: '',
  comment: '',
  recaptcha: ''
};

const DefaultContactInput = () => ({
  date: new Date(),
  type1: 1,
  type2: 1,
  duration: 5,
  comment: ''
});

const DefaultSelfEvalsInput = {
  asiNumbers: '',
  enable: true
};

const DefaultCreateInterventionGroup = {
  name: '',
  appLanguage: 'DE',
  email: '',
  phone: '',
  comment: ''
};

const DefaultEditInterventionGroup = DefaultCreateInterventionGroup;

const DefaultOwnInterventionGroup = DefaultEditInterventionGroup;

Object.freeze(DefaultCreateAddress);
Object.freeze(DefaultEditAddress);

Object.freeze(DefaultCreatePhone);
Object.freeze(DefaultEditPhone);

Object.freeze(DefaultCreateEmail);
Object.freeze(DefaultEditEmail);

Object.freeze(DefaultCreateNurse);
Object.freeze(DefaultEditNurse);

Object.freeze(DefaultCreateProfile);
Object.freeze(DefaultOwnProfile);

Object.freeze(DefaultFindNursesCriteria);

Object.freeze(DefaultSelfEval);
Object.freeze(DefaultCriterionAnswer);

Object.freeze(DefaultCreateMenu);
Object.freeze(DefaultEditMenu);

Object.freeze(DefaultCreatePage);
Object.freeze(DefaultEditPage);

Object.freeze(DefaultEditNews);

Object.freeze(DefaultFindProfilesInput);

Object.freeze(DefaultEditProfile);

Object.freeze(DefaultCreatePeriod);

Object.freeze(DefaultExportDataFilter);

Object.freeze(DefaultJoinInput);

Object.freeze(DefaultContactInput);

Object.freeze(DefaultSelfEvalsInput);

Object.freeze(DefaultCreateInterventionGroup);
Object.freeze(DefaultEditInterventionGroup);

Object.freeze(DefaultOwnInterventionGroup);

export {
  DefaultCreateAddress,
  DefaultEditAddress,
  DefaultCreatePhone,
  DefaultEditPhone,
  DefaultCreateEmail,
  DefaultEditEmail,
  DefaultCreateNurse,
  DefaultEditNurse,
  DefaultCreateProfile,
  DefaultOwnProfile,
  DefaultFindNursesCriteria,
  DefaultSelfEval,
  DefaultCriterionAnswer,
  DefaultCreateMenu,
  DefaultEditMenu,
  DefaultCreatePage,
  DefaultEditPage,
  DefaultEditNews,
  DefaultFindProfilesInput,
  DefaultEditProfile,
  DefaultCreatePeriod,
  DefaultExportDataFilter,
  DefaultJoinInput,
  DefaultContactInput,
  DefaultSelfEvalsInput,
  DefaultCreateInterventionGroup,
  DefaultEditInterventionGroup,
  DefaultOwnInterventionGroup
};
