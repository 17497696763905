import React, { useState } from "react";
import CQuery from "../utils/CQuery";
import gql from "graphql-tag";
import { Field, Formik } from "formik";
import { DefaultFindNursesCriteria } from "../../services/defaults";
import { FormGrid, Section, WideGridItem } from "../utils/Layouts";
import compose from "lodash.flowright";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Collapse, FormControl, InputLabel, MenuItem, withStyles } from "@material-ui/core";
import { CheckboxWithLabel, Select, TextField } from "formik-material-ui";
import { ExpandLess, ExpandMore, Search } from "@material-ui/icons";
import { commonStyles } from "../../services/utils";
import CGrid from "../utils/CGrid";
import FindResults from "../partials/find/FindNursesResults";
import { H4 } from "../utils/Typography";
import { Bold } from "../utils/Typography";

const styles = theme => ({
  ...commonStyles(theme),
  collapse: {
    width: '100%'
  },
  certificationLabel: {
    maxWidth: '100%',
    maxHeight: '3em'
  },
  checkboxRoot: {
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2
  }
});

const initialQuery = lang => gql`
  query {
    domains {
      id
      name {
        ${lang}
      }
    }
    cantons {
      id
      districts {
        id
      }
    }
    practiceLanguages {
      id
      name {
        ${lang}
      }
    }
  }
`;

const findQuery = gql`
  query FindNurses($criteria: FindNursesInput!) {
    findNurses(criteria: $criteria) {
      id
      firstName
      lastName
      pictureUrl
      certified
      psychiatry
      member
      company
      phone {
        number
      }
    }
  }
`;

const buildCriteria = (values, districtsPerCanton, advanced) => {
  const {
    name, domain, certified, canton, districts, rcc, company, cantonAdmission,
    practiceLanguage, interventionDelay, interventionGroup
  } = values;

  const criteria = {};

  if (name)
    criteria.name = name;

  if (domain)
    criteria.domain = domain;

  if (certified === 1)
    criteria.certified = true;
  else if (certified === 2)
    criteria.certified = false;

  if (canton) {
    const cantonDistricts = districtsPerCanton[canton];
    const filteredDistricts = cantonDistricts.filter(d => districts[d]);
    criteria.districts = filteredDistricts.length
      ? filteredDistricts
      : cantonDistricts;
  }

  if (advanced) {
    if (rcc)
      criteria.rcc = rcc;

    if (company)
      criteria.company = company;

    if (cantonAdmission)
      criteria.cantonAdmission = cantonAdmission;

    if (practiceLanguage)
      criteria.practiceLanguage = practiceLanguage;

    if (interventionDelay)
      criteria.interventionDelay = interventionDelay;

    if (interventionGroup)
      criteria.interventionGroup = interventionGroup;
  }

  return criteria;
};

const handleSubmit = (districtsPerCanton, advanced, setCriteria) => values => {
  const criteria = buildCriteria(values, districtsPerCanton, advanced);

  if (Object.keys(criteria).length)
    setCriteria(criteria);
};

const FindNurses = props => {
  const { intl: { locale, formatMessage }, classes } = props;

  const [advanced, setAdvanced] = useState(false);
  const [criteria, setCriteria] = useState(null);
  const skip = !Boolean(criteria);

  const lang = locale.toUpperCase();

  const interventionDelays = [
    { id: 0, name: formatMessage({ id: 'find.interventionDelay.any', defaultMessage: 'Any intervention delay' }) },
    { id: 1, name: formatMessage({ id: 'find.interventionDelay.available', defaultMessage: 'Immediately available' }) },
    { id: 2, name: formatMessage({ id: 'find.interventionDelay.max3Weeks', defaultMessage: 'Max. 3 weeks' }) },
    { id: 3, name: formatMessage({ id: 'find.interventionDelay.max2Months', defaultMessage: 'Max. 2 months' }) },
    { id: 4, name: formatMessage({ id: 'find.interventionDelay.unavailable', defaultMessage: 'Unavailable' }) }
  ];

  return (
    <CQuery query={initialQuery(lang)}>
      {({ data }) => {
        let { domains, cantons, practiceLanguages } = data;
        domains = domains.map(d => Object.assign({}, d, { name: d.name[lang] }));
        domains.splice(0, 0, {
          id: 0,
          name: formatMessage({ id: 'find.domains.any', defaultMessage: 'Any domain' })
        });
        const districtsPerCanton = cantons.reduce((obj, { id, districts }) =>
          Object.assign(obj, { [id]: districts.map(({ id }) => id) }), { 0: [] });
        const areaCantons = cantons.slice(0);
        areaCantons.splice(0, 0, {
          id: 0,
          name: formatMessage({ id: 'find.practiceArea.any', defaultMessage: 'Any practice area' })
        });
        const admissionCantons = cantons.slice(0);
        admissionCantons.splice(0, 0, {
          id: 0,
          name: formatMessage({ id: 'find.cantonAdmission.any', defaultMessage: 'Unspecified' })
        });
        practiceLanguages = practiceLanguages
          .map(l => Object.assign({}, l, { name: l.name[lang] }))
          .sort(({ name: a }, { name: b }) => a < b ? -1 : (a > b ? 1 : 0));
        practiceLanguages.splice(0, 0, {
          id: 0,
          name: formatMessage({ id: 'find.practiceLanguages.any', defaultMessage: 'Any practice language' })
        });

        return (
          <>
            <WideGridItem >
              <H4 id="find.title" defaultMessage="Find a freelance nurse" />
            </WideGridItem>
            <Section className="custom_bg">
              <Formik onSubmit={handleSubmit(districtsPerCanton, advanced, setCriteria)}
                initialValues={DefaultFindNursesCriteria}>
                {({ values, isSubmitting, dirty, setSubmitting }) => (
                  <>
                    <FormGrid>
                      <Section item xs={12} md={4} spacing={16} alignItems='flex-start'
                        alignContent='flex-start'>
                        <WideGridItem>
                          <FormControl fullWidth>
                            <Field component={TextField} name='name'
                              label={`${formatMessage({
                                id: 'general.lastName',
                                defaultMessage: 'Last name'
                              })} ${formatMessage({
                                id: 'general.firstName',
                                defaultMessage: 'First name'
                              })}`} />
                          </FormControl>
                        </WideGridItem>
                        <WideGridItem>
                          <FormControl fullWidth>
                            <InputLabel htmlFor='domain'>
                              <FormattedMessage id='find.domain' defaultMessage='Domain' />
                            </InputLabel>
                            <Field component={Select} name='domain'>
                              {domains.map(({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                            </Field>
                          </FormControl>
                        </WideGridItem>
                      </Section>
                      <Section item xs={12} md={4} spacing={16} alignItems='flex-start'
                        alignContent='flex-start'>
                        <WideGridItem>
                          <FormControl fullWidth>
                            <InputLabel htmlFor='certified'>
                              <FormattedMessage id='nurse.certification' defaultMessage='Quality certification' />
                            </InputLabel>
                            <Field component={Select} name='certified'>
                              <MenuItem value={0}>
                                <FormattedMessage id='find.label.any' defaultMessage='With or without label' />
                              </MenuItem>
                              <MenuItem value={1}>
                                <FormattedMessage id='find.label.with' defaultMessage='With label' />
                              </MenuItem>
                              <MenuItem value={2}>
                                <FormattedMessage id='find.label.without' defaultMessage='Without label' />
                              </MenuItem>
                            </Field>
                          </FormControl>
                        </WideGridItem>
                        <WideGridItem>
                          <FormControl fullWidth>
                            <InputLabel htmlFor='canton'>
                              <FormattedMessage id='find.practiceArea' defaultMessage='Practice area' />
                            </InputLabel>
                            <Field name='canton' component={Select}>
                              {areaCantons.map(({ id, name }) => <MenuItem key={id} value={id}>{name || id}</MenuItem>)}
                            </Field>
                          </FormControl>
                        </WideGridItem>
                      </Section>
                      {values.canton !== 0 && districtsPerCanton[values.canton].length > 1 &&
                        <Section item xs={12} md={4} spacing={8} alignItems="center" alignContent="center">
                          {districtsPerCanton[values.canton].map(district =>
                            <Field key={district} name={`districts["${district}"]`} component={CheckboxWithLabel}
                              Label={{ label: district }} classes={{ root: classes.checkboxRoot }} />)}
                        </Section>}
                      <WideGridItem container spacing={8}>
                        <CGrid item>
                          <Button
                            disabled={isSubmitting || !dirty ||
                              (criteria && JSON.stringify(criteria) === JSON.stringify(buildCriteria(values, districtsPerCanton, advanced)))}
                            type="submit" variant="contained" color="secondary">
                            <Search className={classes.buttonIconLeft} />
                            <FormattedMessage id='general.action.search' defaultMessage='Search' />
                          </Button>
                        </CGrid>
                        <CGrid item>
                          <Button variant="outlined" onClick={() => setAdvanced(!advanced)} disabled={isSubmitting}>
                            {advanced
                              ? <><ExpandLess className={classes.buttonIconLeft} />
                                <FormattedMessage id='find.simple' defaultMessage='Simple search' /></>
                              : <><ExpandMore className={classes.buttonIconLeft} />
                                <FormattedMessage id='find.advanced' defaultMessage='Advanced search' /></>}
                          </Button>
                        </CGrid>
                      </WideGridItem>
                      <Collapse in={advanced} timeout="auto" classes={{ wrapperInner: classes.collapse }}>
                        <WideGridItem container>
                          <Section item xs={12} md={4} spacing={16} alignItems='flex-start'
                            alignContent='flex-start'>
                            <WideGridItem>
                              <FormControl fullWidth>
                                <Field component={TextField} name='interventionGroup'
                                  label={formatMessage({
                                    id: 'nurse.interventionGroup',
                                    defaultMessage: 'Intervention group'
                                  })} />
                              </FormControl>
                            </WideGridItem>
                            <WideGridItem>
                              <FormControl fullWidth>
                                <InputLabel htmlFor='practiceLanguage'>
                                  <FormattedMessage id='find.practiceLanguage' defaultMessage='Language' />
                                </InputLabel>
                                <Field component={Select} name='practiceLanguage'>
                                  {practiceLanguages.map(({ id, name }) =>
                                    <MenuItem key={id} value={id}>{name}</MenuItem>)}
                                </Field>
                              </FormControl>
                            </WideGridItem>
                          </Section>
                          <Section item xs={12} md={4} spacing={16} alignItems='flex-start' alignContent='flex-start'>
                            <WideGridItem>
                              <FormControl fullWidth>
                                <InputLabel htmlFor='interventionDelay'>
                                  <FormattedMessage id='nurse.interventionDelay' defaultMessage='Intervention delay' />
                                </InputLabel>
                                <Field component={Select} name='interventionDelay'>
                                  {interventionDelays.map(({ id, name }) =>
                                    <MenuItem key={id} value={id}>{name}</MenuItem>)}
                                </Field>
                              </FormControl>
                            </WideGridItem>
                            <WideGridItem>
                              <FormControl fullWidth>
                                <Field component={TextField} name='company'
                                  label={formatMessage({
                                    id: 'nurse.company',
                                    defaultMessage: 'Company'
                                  })} />
                              </FormControl>
                            </WideGridItem>
                          </Section>
                          <Section item xs={12} md={4} spacing={16} alignItems='flex-start'
                            alignContent='flex-start'>
                            <WideGridItem>
                              <FormControl fullWidth>
                                <Field component={TextField} name='rcc'
                                  label={formatMessage({
                                    id: 'nurse.rcc',
                                    defaultMessage: 'RCC/ZSR number'
                                  })} />
                              </FormControl>
                            </WideGridItem>
                            <WideGridItem>
                              <FormControl fullWidth>
                                <InputLabel htmlFor='cantonAdmission'>
                                  <FormattedMessage id='find.cantonAdmission' defaultMessage='Canton admission' />
                                </InputLabel>
                                <Field component={Select} name='cantonAdmission'>
                                  {admissionCantons.map(({ id, name }) => <MenuItem key={id}
                                    value={id}>{name || id}</MenuItem>)}
                                </Field>
                              </FormControl>
                            </WideGridItem>
                          </Section>
                        </WideGridItem>
                      </Collapse>
                    </FormGrid>
                    <WideGridItem>
                      <Bold id='nurse.psychiatryAsterixContent' defaultMessage='Psychiatry experience*' />
                    </WideGridItem>
                    <CQuery query={findQuery} variables={{ criteria }} fetchPolicy="no-cache" skip={skip}
                      onCompleted={() => setSubmitting(false)} onError={() => setSubmitting(false)}>
                      {({ data }) => {
                        if (skip)
                          return null;

                        return <FindResults nurses={data.findNurses} />
                      }}
                    </CQuery>
                  </>
                )}
              </Formik>
            </Section>
          </>
        );
      }}
    </CQuery>
  );
};

export default compose(
  withStyles(styles),
  injectIntl
)(FindNurses);
