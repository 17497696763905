import React from "react";
import {Section} from "./Layouts";
import {withStyles} from "@material-ui/core";
import compose from "lodash.flowright";
import {Text} from "./Typography";

const styles = {
  centerText: {
    textAlign: 'center'
  }
};

const Error = props => {
  const {error, classes} = props;
  const isProd = process.env.NODE_ENV === 'production';

  if (!isProd)
    console.error(error);

  return (
    <Section justify="center" alignItems="center">
      {isProd || !error
        ? <Text className={classes.centerText} id='error.unspecified'
                defaultMessage='An unknown error occurred. Try to reload the page'/>
        : <Text className={classes.centerText}>{JSON.stringify(error)}</Text>}
    </Section>
  );
};

export default compose(
  withStyles(styles)
)(Error);